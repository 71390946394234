import {
  DEFAULT_TAX_RATES,
  offsetEstimatedTaxImpact,
  ZERO,
} from "@frec-js/common";
import Decimal from "decimal.js";
import { useMemo } from "react";

import {
  EstimateDirectIndexGainLossInput,
  EstimateDirectIndexGainLossQuery,
  useEstimateDirectIndexGainLossQuery,
  UserTaxRates,
} from "../generated/graphql";

const computeTaxRates = (userTaxRates: UserTaxRates) => {
  // income tax rate + state capital gains tax rate
  const shortTermGainsRate = userTaxRates.federalIncomeTaxRate.plus(
    userTaxRates.stateCapitalGainsTaxRate,
  );

  // long term capital gains tax rate + state capital gains tax rate
  const longTermGainsRate = userTaxRates.federalCapitalGainsTaxRate.plus(
    userTaxRates.stateCapitalGainsTaxRate,
  );

  return {
    shortTermGainsRate,
    longTermGainsRate,
  };
};

const computeTaxes = (
  shortTermGainsRate: Decimal,
  longTermGainsRate: Decimal,
  netShortTermGains: Decimal,
  netLongTermGains: Decimal,
) => {
  const shortTermTaxes = netShortTermGains.times(shortTermGainsRate.div(100));
  const longTermTaxes = netLongTermGains.times(longTermGainsRate.div(100));
  const totalTaxes = shortTermTaxes.plus(longTermTaxes);
  return {
    shortTerm: shortTermTaxes,
    longTerm: longTermTaxes,
    totalTaxes,
  };
};

export type TermEstimatedTaxImpact = {
  taxRate: Decimal;
  gains: Decimal;
  netGains: Decimal;
  taxes: Decimal;
  lossHarvestPotential: Decimal;
};

export type ComputedEstimatedTaxImpact = {
  shortTerm: TermEstimatedTaxImpact;
  longTerm: TermEstimatedTaxImpact;
  totalTaxes: Decimal;
};

const _computeEstimatedTaxImpact = (
  estimateDirectIndexGainLoss: EstimateDirectIndexGainLossQuery["estimateDirectIndexGainLoss"],
  userTaxRates: UserTaxRates,
  lossHarvestPotentialEnabled: boolean,
): ComputedEstimatedTaxImpact => {
  const { totalShortTermGains, totalLongTermGains, lossHarvestPotential } =
    estimateDirectIndexGainLoss;

  const lossHarvestPotentialForCalculations = lossHarvestPotentialEnabled
    ? lossHarvestPotential
    : ZERO;

  const { shortTermGainsRate, longTermGainsRate } =
    computeTaxRates(userTaxRates);

  // Offset gains with losses first, without LHP
  const gains = offsetEstimatedTaxImpact(
    totalShortTermGains,
    totalLongTermGains,
    ZERO,
  );
  // Offset with LHP
  const netGains = offsetEstimatedTaxImpact(
    totalShortTermGains,
    totalLongTermGains,
    lossHarvestPotentialForCalculations,
  );

  // Compute taxes
  const {
    shortTerm: shortTermTaxes,
    longTerm: longTermTaxes,
    totalTaxes,
  } = computeTaxes(
    shortTermGainsRate,
    longTermGainsRate,
    netGains.shortTermGains,
    netGains.longTermGains,
  );

  return {
    shortTerm: {
      taxRate: shortTermGainsRate,
      gains: gains.shortTermGains,
      netGains: netGains.shortTermGains,
      taxes: shortTermTaxes,
      lossHarvestPotential, // short term only
    },
    longTerm: {
      taxRate: longTermGainsRate,
      gains: gains.longTermGains,
      netGains: netGains.longTermGains,
      taxes: longTermTaxes,
      lossHarvestPotential: ZERO,
    },
    totalTaxes: totalTaxes,
  };
};

const computeEstimatedTaxImpact = (
  { estimateDirectIndexGainLoss }: EstimateDirectIndexGainLossQuery,
  userTaxRates: UserTaxRates,
): {
  withLHP: ComputedEstimatedTaxImpact;
  withoutLHP: ComputedEstimatedTaxImpact;
} => {
  const withLHP = _computeEstimatedTaxImpact(
    estimateDirectIndexGainLoss,
    userTaxRates,
    true,
  );
  const withoutLHP = _computeEstimatedTaxImpact(
    estimateDirectIndexGainLoss,
    userTaxRates,
    false,
  );
  return {
    withLHP,
    withoutLHP,
  };
};

const INITIAL_TERM_ESTIMATED_TAX_IMPACT: TermEstimatedTaxImpact = {
  taxRate: ZERO,
  gains: ZERO,
  netGains: ZERO,
  taxes: ZERO,
  lossHarvestPotential: ZERO,
};

const INITIAL_ESTIMATED_TAX_IMPACT: ComputedEstimatedTaxImpact = {
  shortTerm: INITIAL_TERM_ESTIMATED_TAX_IMPACT,
  longTerm: INITIAL_TERM_ESTIMATED_TAX_IMPACT,
  totalTaxes: ZERO,
};

export const useEstimatedTaxImpact = (
  input: EstimateDirectIndexGainLossInput,
  lossHarvestPotentialEnabled: boolean,
  userTaxRates?: UserTaxRates,
  skip?: boolean,
) => {
  const { data, loading, error, refetch } = useEstimateDirectIndexGainLossQuery(
    {
      variables: {
        input,
      },
      notifyOnNetworkStatusChange: true,
      skip,
    },
  );

  return useMemo(() => {
    // Initial state is zero
    const initial = {
      loading,
      error,
      refetch,
      estimatedTaxImpact: INITIAL_ESTIMATED_TAX_IMPACT,
      showLossHarvestPotential: false,
      noTaxImpact: false,
    };

    if (skip) {
      return { ...initial, noTaxImpact: true };
    }

    if (loading || !data) {
      return initial;
    }

    const { withLHP, withoutLHP } = computeEstimatedTaxImpact(
      data,
      userTaxRates ?? (DEFAULT_TAX_RATES as UserTaxRates),
    );
    const estimatedTaxImpact = lossHarvestPotentialEnabled
      ? withLHP
      : withoutLHP;

    // All the data available to compute the estimated tax impact
    return {
      ...initial,
      estimatedTaxImpact,
      // If there is no LHP, don't show it at all
      showLossHarvestPotential:
        !estimatedTaxImpact.shortTerm.lossHarvestPotential.isZero(),
      // If there is no tax impact when LHP is not enabled, don't show details
      noTaxImpact: withoutLHP.totalTaxes.isZero(),
    };
  }, [
    loading,
    data,
    userTaxRates,
    error,
    refetch,
    lossHarvestPotentialEnabled,
    skip,
  ]);
};
