import Decimal from "decimal.js";

import {
  MAX_LOAN_AMOUNT,
  MIN_EQUITY_FOR_LOAN,
  MIN_EQUITY_FOR_LOAN_SOFT,
  MIN_HOLDINGS_FOR_LOAN_SOFT,
  MIN_LOAN_ADDITION,
  MIN_LOAN_AMOUNT,
} from "../constants/borrow";
import {
  AcatsTransferStatus,
  MarginInterestFeeStatus,
  MoneyMovementSourceType,
} from "../generated/graphql";
import {
  getEquityValuesNoCash,
  getSecurityHoldingsValue,
  Holding,
} from "../portfolio_utils/marginUtils";
import { formatUsd, ZERO } from "../utils";

export type MarginInterestFeeArgs = {
  amount: Decimal;
  clearingAccountId: string;
  status: MarginInterestFeeStatus;
};

export type CashTransferFeeArgs = {
  amount: Decimal;
};

export type LoanRepaymentValidationArgs = {
  requestedLoan: Decimal;
  frecCash: Decimal;
  existingLoan: Decimal;
  method?: MoneyMovementSourceType;
};

export type LoanValidationArgs = {
  requestedLoan: Decimal;
  existingLoan: Decimal;
  holdings: Holding[];
  borrowingPower?: Decimal;
  applySoftRules: boolean;

  // During DI onboarding, we allow customers to borrow from the borrowing power
  // of the equities they are about to own. These parameters will be used to:
  // 1) Calculate the additional borrowing power
  // 2) Bypass the minimum equity requirement to utilize margin
  diOnboarding?: boolean;
  diAmount?: Decimal;
  diInitialLTV?: Decimal;
};

export type LoanValidationResult = {
  error: boolean;
  message?: string;
  equityNeeded?: Decimal;
  borrowingPowerNeeded?: Decimal;
};

export type FeeLoanArgs = {
  requestedLoan: Decimal;
  loanClearingAccountId: string;
  fee: MarginInterestFeeArgs | CashTransferFeeArgs;
};

export type AcatsTransferredLoanArgs = {
  requestedLoan: Decimal;
  acatsStatus: AcatsTransferStatus;
  acatsDebitCash: Decimal;
};

/**
 * @deprecated use MoneyMovementSourceType instead
 */
export enum LoanPaymentMethodEnum {
  FrecCash = "FrecCash",
  Ach = "Ach",
}

/**
 * Checks loan repayment rules, designed for use by both FE and BE systems.
 *
 * Note: Repayment amounts are negated already at this point as that is
 * how the DB represents repayment. As a result, we use `negated()` below
 * when checking values.
 */
export function runRepayLoanValidations(
  input: LoanRepaymentValidationArgs,
): LoanValidationResult {
  const { requestedLoan, frecCash, existingLoan, method } = input;

  if (requestedLoan.negated().lessThanOrEqualTo(0))
    return {
      error: true,
      message: "Loan repayment amount must be positive.",
    };

  if (
    requestedLoan.negated().greaterThan(frecCash) &&
    method !== MoneyMovementSourceType.Ach
  )
    return {
      error: true,
      message:
        "You don’t have enough cash in your account. Deposit extra funds using Transfers.",
    };

  if (requestedLoan.negated().greaterThan(existingLoan))
    return {
      error: true,
      message: "Loan repayment amount must not exceed outstanding loan.",
    };

  return { error: false };
}

/**
 * Checks loan rules related to acatsTransfers coming in with a debit cash entry.
 *
 */
export function runAcatsTransferredLoanValidations(
  input: AcatsTransferredLoanArgs,
): LoanValidationResult {
  const { requestedLoan, acatsStatus, acatsDebitCash } = input;

  if (requestedLoan.lessThanOrEqualTo(0))
    return {
      error: true,
      message: "Loan from acats transfers must be positive.",
    };

  if (acatsStatus !== AcatsTransferStatus.Complete)
    return {
      error: true,
      message: "Acats transfer must be in a complete state to proceed.",
    };

  if (!requestedLoan.equals(acatsDebitCash))
    return {
      error: true,
      message: "Loan requested must equal debit transferred.",
    };

  return { error: false };
}

/**
 * Runs loan validations for CashTransferFee and MarginInterestFee
 */
export function runFeeLoanValidations(
  input: FeeLoanArgs,
): LoanValidationResult {
  if (input.requestedLoan.lessThanOrEqualTo(0)) {
    return {
      error: true,
      message: "Loan amount must be positive.",
    };
  }

  if (!input.requestedLoan.equals(input.fee.amount)) {
    return {
      error: true,
      message: "Loan amount must be equal to the fee.",
    };
  }

  if (
    "clearingAccountId" in input.fee &&
    input.fee.clearingAccountId !== input.loanClearingAccountId
  ) {
    return {
      error: true,
      message: "Loan and margin interest fee should be for same account.",
    };
  }

  return { error: false };
}

/**
 * Checks loan validation rules for new loans, designed for use by both FE and BE systems.
 */
export function runInitiateLoanValidations(
  input: LoanValidationArgs,
): LoanValidationResult[] {
  const {
    requestedLoan,
    existingLoan,
    borrowingPower,
    diOnboarding,
    diAmount,
    diInitialLTV,
    holdings,
    applySoftRules,
  } = input;

  const results: LoanValidationResult[] = [];

  // Return early because it prevents invalid input (e.g. negative loan).
  if (requestedLoan.lessThan(MIN_LOAN_ADDITION))
    return [
      {
        error: true,
        message: `The minimum incremental loan amount is ${formatUsd(
          MIN_LOAN_ADDITION,
          0,
          2,
        )}.`,
      },
    ];

  const targetLoanAmount = existingLoan.add(input.requestedLoan);
  const equityAtTarget = getEquityValuesNoCash({
    holdings,
    loanAmount: targetLoanAmount,
  });
  const holdingsValue = getSecurityHoldingsValue(holdings);

  if (targetLoanAmount.greaterThan(MAX_LOAN_AMOUNT))
    return [
      {
        error: true,
        message: `Total loan amount may not exceed ${formatUsd(
          MAX_LOAN_AMOUNT,
        )}.`,
      },
    ];

  if (targetLoanAmount.lessThan(MIN_LOAN_AMOUNT))
    return [
      {
        error: true,
        message: `The minimum loan amount is ${formatUsd(MIN_LOAN_AMOUNT)}.`,
      },
    ];

  const additionalBorrowingPower =
    diOnboarding && diAmount && diInitialLTV
      ? diInitialLTV.mul(diAmount)
      : ZERO;
  const totalBorrowingPower = additionalBorrowingPower.add(
    borrowingPower ? borrowingPower : ZERO,
  );
  if (requestedLoan.gt(totalBorrowingPower))
    results.push({
      error: true,
      message: "Insufficient holdings: Loan exceeds borrowing power.",
      borrowingPowerNeeded: requestedLoan.minus(borrowingPower || 0),
    });

  const totalEquity = equityAtTarget.equity.add(diAmount ? diAmount : ZERO);
  if (totalEquity.lt(MIN_EQUITY_FOR_LOAN))
    results.push({
      error: true,
      message: `Insufficient holdings: Equity at cannot fall below ${formatUsd(
        MIN_EQUITY_FOR_LOAN,
      )}.`,
      equityNeeded: totalEquity.neg().add(MIN_EQUITY_FOR_LOAN),
    });

  // If soft rules are to apply
  if (applySoftRules) {
    if (holdingsValue.lessThan(MIN_HOLDINGS_FOR_LOAN_SOFT))
      results.push({
        error: true,
        message: `Insufficient holdings: Holdings value must exceed ${formatUsd(
          MIN_HOLDINGS_FOR_LOAN_SOFT,
        )} for loan issue.`,
        equityNeeded: holdingsValue.neg().add(MIN_HOLDINGS_FOR_LOAN_SOFT),
      });
    if (totalEquity.lt(MIN_EQUITY_FOR_LOAN_SOFT))
      // put this at the front of the array, because equity rule above will also be in there
      results.push({
        error: true,
        message: `Insufficient holdings: Equity at cannot fall below ${formatUsd(
          MIN_EQUITY_FOR_LOAN_SOFT,
        )}.`,
        equityNeeded: totalEquity.neg().add(MIN_EQUITY_FOR_LOAN_SOFT),
      });
  }
  return results;
}
