import { InMemoryCache } from "@apollo/client";
import {
  scalarTypePolicies,
  StrictTypedTypePolicies,
} from "@frec-js/common-client";
import { deepmerge } from "deepmerge-ts";

const typePolicies: StrictTypedTypePolicies = {};

export const apolloCache: InMemoryCache = new InMemoryCache({
  typePolicies: deepmerge(
    scalarTypePolicies,
    typePolicies,
  ) as StrictTypedTypePolicies,
});
