import { DateOnly } from "@frec-js/common";
import { DateTime } from "luxon";

import {
  DateInterval,
  useHistoricalStockPricesQuery,
} from "../../generated/graphql";
import { StockChartPeriod } from "../types";

export const useHistoricalStockPrices = (
  symbols: string[],
  period: StockChartPeriod,
  skip: boolean,
) => {
  const endDate = new Date();
  const startDate = getStartDateFromPeriod(endDate, period);
  const stockPrices = useHistoricalStockPricesQuery({
    variables: {
      input: {
        symbols,
        interval:
          period === StockChartPeriod["ALL"]
            ? DateInterval.Month
            : period === StockChartPeriod["5Y"]
              ? DateInterval.Week
              : DateInterval.Day,
        startDate: startDate && DateOnly.fromDateUTC(startDate),
        endDate: DateOnly.fromDateUTC(endDate),
      },
    },
    skip: skip || !symbols.length,
  });
  return stockPrices;
};

export const getStartDateFromPeriod = (
  endDate: Date,
  period: StockChartPeriod,
): Date | undefined => {
  switch (period) {
    case StockChartPeriod["1D"]:
      // needs to use previous day's data from market hours query
      return undefined;
    case StockChartPeriod["1W"]:
      return DateTime.fromJSDate(endDate).minus({ week: 1 }).toJSDate();
    case StockChartPeriod["1M"]:
      return DateTime.fromJSDate(endDate).minus({ month: 1 }).toJSDate();
    case StockChartPeriod["3M"]:
      return DateTime.fromJSDate(endDate).minus({ month: 3 }).toJSDate();
    case StockChartPeriod["6M"]:
      return DateTime.fromJSDate(endDate).minus({ month: 6 }).toJSDate();
    case StockChartPeriod["1Y"]:
      return DateTime.fromJSDate(endDate).minus({ year: 1 }).toJSDate();
    case StockChartPeriod["5Y"]:
      return DateTime.fromJSDate(endDate).minus({ year: 5 }).toJSDate();
    case StockChartPeriod["ALL"]:
    default:
      return undefined;
  }
};
