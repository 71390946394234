import { MAX_LOAN_AMOUNT, MIN_LOAN_AMOUNT } from "@frec-js/common";
import Decimal from "decimal.js";

const SLIDER_STEP = new Decimal(100);
const INPUT_STEP = new Decimal(1_000);

const MAX_SLIDER_AMOUNT = new Decimal(MAX_LOAN_AMOUNT);
const MIN_SLIDER_AMOUNT = new Decimal(MIN_LOAN_AMOUNT);

const transform = (n: Decimal) => n.pow(3);
const untransform = (n: Decimal) => Decimal.cbrt(n);

const positionToValue = (linearP: Decimal) => {
  const p = transform(linearP);
  return p.eq(1)
    ? MAX_SLIDER_AMOUNT
    : p.eq(0)
      ? MIN_SLIDER_AMOUNT
      : Decimal.max(
          MIN_SLIDER_AMOUNT,
          p
            .times(MAX_SLIDER_AMOUNT.minus(MIN_SLIDER_AMOUNT))
            .plus(MIN_SLIDER_AMOUNT),
        );
};

const valueToPosition = (v: Decimal) => {
  return untransform(
    Decimal.max(
      0,
      Decimal.min(
        1,
        v
          .minus(MIN_SLIDER_AMOUNT)
          .dividedBy(MAX_SLIDER_AMOUNT.minus(MIN_SLIDER_AMOUNT)),
      ),
    ),
  );
};

export const SliderUtils = {
  INPUT_STEP,
  MAX_SLIDER_AMOUNT,
  MIN_SLIDER_AMOUNT,
  positionToValue,
  SLIDER_STEP,
  valueToPosition,
};
