import {
  LDClient,
  LDContext,
  LDFlagSet,
  useLDClient,
} from "launchdarkly-react-client-sdk";
import { useEffect, useState } from "react";

import { FeatureFlagAnonymousKey } from "../constants";

/**
 * We need to clean up this file, check the mobile version for reference
 */

type FeatureFlagUserFragment = {
  id: string;
  userContactEmail?: {
    email?: string;
  } | null;
  isEmployee: boolean;
};
export type FeatureFlagUserObject = {
  user: FeatureFlagUserFragment | null;
  isEmployee?: boolean;
};

export type FeatureFlagLogger = {
  debug(message: string, messageContext?: object): void;
  info(message: string, messageContext?: object): void;
  warn(message: string, messageContext?: object): void;
  error(message: string, messageContext?: object): void;
};

type FlagBehavior<T> = (() => T) | T;

/**
 * Behavior that we wish all types of flags to
 */
export type FallbackBehaviors<R> = {
  missing: FlagBehavior<R>;
  loading?: FlagBehavior<R>;
};

export type StringBehaviors<R> = Record<string, FlagBehavior<R>> &
  FallbackBehaviors<R>;

export const useStringFeatureFlag: <R>(
  flagName: string,
  behavior: StringBehaviors<R>,
  userObject: FeatureFlagUserObject,
  logger?: FeatureFlagLogger,
) => R = <R>(
  flagName: string,
  behavior: StringBehaviors<R>,
  userObject: FeatureFlagUserObject,
  logger?: FeatureFlagLogger,
) => {
  const { isLoading, client } = useClientWithStatus(userObject, logger);

  const resolvedFlagValue: string | undefined = valueFromAllFlagsHelper<string>(
    client?.allFlags(),
    flagName,
    (t): t is string => typeof t === "string",
  );

  if (isLoading && behavior.loading) {
    return _executeBehavior(behavior.loading);
  } else if (resolvedFlagValue !== undefined) {
    const behaviorElement = behavior[resolvedFlagValue];
    if (!behaviorElement) {
      logger?.warn("hook does not handle found flag value", {
        flagValue: resolvedFlagValue,
      });
    }
    return behaviorElement
      ? _executeBehavior(behaviorElement)
      : _executeBehavior(behavior.missing);
  } else {
    return _executeBehavior(behavior.missing);
  }
};

export type NumberBehaviors = Record<string, FlagBehavior<number>> &
  FallbackBehaviors<number>;

export const useNumberFeatureFlag: (
  flagName: string,
  behavior: NumberBehaviors,
  userObject: FeatureFlagUserObject,
  logger?: FeatureFlagLogger,
) => number = (
  flagName: string,
  behavior: NumberBehaviors,
  userObject: FeatureFlagUserObject,
  logger?: FeatureFlagLogger,
) => {
  const { isLoading, client } = useClientWithStatus(userObject, logger);

  const resolvedFlagValue: number | undefined = valueFromAllFlagsHelper<number>(
    client?.allFlags(),
    flagName,
    (t): t is number => typeof t === "number",
  );

  if (isLoading && behavior.loading) {
    return _executeBehavior(behavior.loading);
  } else if (resolvedFlagValue !== undefined) {
    return _executeBehavior(resolvedFlagValue);
  } else {
    return _executeBehavior(behavior.missing);
  }
};

export const useObjectFeatureFlag: <R>(
  flagName: string,
  behavior: FallbackBehaviors<R>,
  userObject: FeatureFlagUserObject,
  logger?: FeatureFlagLogger,
) => R = <R>(
  flagName: string,
  behavior: FallbackBehaviors<R>,
  userObject: FeatureFlagUserObject,
  logger?: FeatureFlagLogger,
) => {
  const { isLoading, client } = useClientWithStatus(userObject, logger);

  const resolvedValue = valueFromAllFlagsHelper<object>(
    client?.allFlags(),
    flagName,
    (t): t is object => typeof t === "object",
  );

  // Simply return the object if it's defined, else do the behaviors
  if (isLoading && behavior.loading) {
    return _executeBehavior(behavior.loading);
  } else if (resolvedValue !== undefined) {
    return resolvedValue
      ? _executeBehavior(resolvedValue as FlagBehavior<R>)
      : _executeBehavior(behavior.missing);
  } else {
    return _executeBehavior(behavior.missing);
  }
};

export type BooleanBehaviors<R> = {
  true: FlagBehavior<R>;
  false: FlagBehavior<R>;
} & FallbackBehaviors<R>;
export const useBooleanFeatureFlag: <R>(
  flagName: string,
  behavior: BooleanBehaviors<R>,
  userObject: FeatureFlagUserObject,
  logger?: FeatureFlagLogger,
) => R = <R>(
  flagName: string,
  behavior: BooleanBehaviors<R>,
  userObject: FeatureFlagUserObject,
  logger?: FeatureFlagLogger,
) => {
  const { isLoading, client } = useClientWithStatus(userObject, logger);

  const resolvedValue = valueFromAllFlagsHelper<boolean>(
    client?.allFlags(),
    flagName,
    (t): t is boolean => typeof t === "boolean",
  );

  /**
   * 1. If we are loading, return the loading behavior if defined
   * 2. If the flag value has been set, execute that branch
   * 3. If the `allFlags` has it (initial load) then return that
   * 4. Otherwise, missing
   */
  if (isLoading && behavior.loading) {
    return _executeBehavior(behavior.loading);
  } else if (resolvedValue !== undefined) {
    return resolvedValue
      ? _executeBehavior(behavior.true)
      : _executeBehavior(behavior.false);
  } else {
    return _executeBehavior(behavior.missing);
  }
};

const MAX_LAUNCH_DARKLY_INIT_TIME = 5_000;

/**
 * For launch darkly, we want to treat accounts as employees if they
 * have a @frec.com email address (i.e. it's common to use myName+some_thing@frec.com
 * for testing purposes)
 *
 * Visible for testing
 */
export const isConsideredLDEmployee = (
  user?: FeatureFlagUserObject["user"],
  isEmployee?: boolean,
) => {
  return !!(
    isEmployee ||
    user?.userContactEmail?.email?.endsWith("@frec.com") ||
    user?.isEmployee
  );
};

/**
 * Internal hook which sets up the client and loading state
 */
const useClientWithStatus: (
  userObject: FeatureFlagUserObject,
  logger?: FeatureFlagLogger,
) => {
  isLoading: boolean;
  client?: LDClient;
} = (userObject, logger) => {
  const client = useLDClient();
  const { user, isEmployee: userIsEmployee } = userObject;
  const isEmployee: boolean = isConsideredLDEmployee(user, userIsEmployee);

  useEffect(() => {
    const ldUser: LDContext = {
      kind: "user",
      key: user?.id ?? FeatureFlagAnonymousKey,
      isEmployee,
    };
    if (client) {
      client.identify(
        ldUser,
        undefined,
        (err) =>
          err && logger?.error("failed to identify user", { err, ldUser }),
      );
    }
  }, [client, isEmployee, logger, user?.id]);
  const [isLoading, setIsLoading] = useState(true);

  /**
   * Do not allow the loading of Launch Darkly to take longer than
   * MAX_LAUNCH_DARKLY_INIT_TIME
   */
  useEffect(() => {
    setTimeout(() => {
      isLoading && setIsLoading(false);
    }, MAX_LAUNCH_DARKLY_INIT_TIME);
  }, [isLoading]);

  useEffect(() => {
    client &&
      client.waitUntilGoalsReady().then(
        () => setIsLoading(false),
        (err) => {
          logger?.warn("LaunchDarkly failure to initialize", err);
          setIsLoading(false);
        },
      );
  }, [client, logger]);

  return { isLoading, client };
};

export const _executeBehavior = <T>(behavior: FlagBehavior<T>) =>
  behavior instanceof Function ? behavior() : behavior;

/**
 * Pull the key from the flag set iff it is defined and of the correct type, otherwise
 * undefined
 */
const valueFromAllFlagsHelper: <T>(
  allFlags: LDFlagSet | undefined,
  flagName: string,
  predicate: (value: unknown) => value is T,
  logger?: FeatureFlagLogger,
) => T | undefined = <T>(
  allFlags: LDFlagSet | undefined,
  flagName: string,
  predicate: (value: unknown) => value is T,
  logger?: FeatureFlagLogger,
) => {
  if (allFlags && flagName in allFlags) {
    const flagValue = allFlags[flagName];
    const predicatePassed = predicate(flagValue);
    if (!predicatePassed) {
      logger?.warn("Found incorrect type for flag", {
        flagName,
        flagValue,
        type: typeof flagValue,
      });
    }
    return predicatePassed ? flagValue : undefined;
  }
};
