import Decimal from "decimal.js";

import {
  ASSUMED_LTV_OF_ACAT,
  BORROWED_DIRECT_INDEX_ID,
  DIVERSIFIED_ETF_SYMBOLS,
  MIN_EQUITY_FOR_LOAN,
} from "../constants";
import {
  MarginAccountState,
  MmfMetadata,
  SecuritySubType,
  SecurityTradeStatus,
  SecurityType,
  SubAccountType,
} from "../generated/graphql";
import { makeDecimal } from "../utils";

// Maximum fraction of portfolio before a holding is concentrated
const CONCENTRATION_MAX = 0.25;

/**
 * Holdings object, describes a stock that are held in the least complex
 * way possible.
 *
 * TODO: Holding/RichHolding should be in a separate file.
 */
export type Holding = {
  symbol: string;
  quantity: Decimal;
  price: Decimal;
  marginRequirement: Decimal;
  type: SecurityType;
  subType: SecuritySubType;
};

export type RichHolding = Holding & {
  securityId?: string;
  subAccountId: string;
  subAccountType: SubAccountType;
  avgCostBasis?: Decimal;
  name?: string;
  buyStatus: SecurityTradeStatus;
  sellStatus: SecurityTradeStatus;
  change?: Decimal;
  changesPercentage?: Decimal;
  open?: Decimal;
  previousClose?: Decimal;
  volume?: Decimal;
  mmfMetadata?: MmfMetadata;
  dividendYtd?: Decimal;
};

const ONE = new Decimal(1);

/**
 * Importantly this function does not return the cash-portion of a portfolio's value.
 */
export const getSecurityHoldingsValue = (
  holdings: { price: Decimal | number; quantity: Decimal | number }[],
): Decimal => {
  return holdings.reduce(
    (prev, curr) => prev.add(makeDecimal(curr.quantity).mul(curr.price)),
    new Decimal(0),
  );
};

/**
 * We do not apply concentration requirements to some diversified ETFs based on
 * the symbol.
 */

/**
 * Encodes business logic for concentrated margin requirements, see: go/cmargin for details.
 *
 * Does not check portfolioValue > $2.5M, as APEX would.
 */
export const getConcentratedAdjustedMargin = (
  holding: Holding,
  portfolioValue: Decimal,
): Decimal => {
  const hVal = holding.price.mul(holding.quantity);

  if (
    DIVERSIFIED_ETF_SYMBOLS.includes(holding.symbol) ||
    holding.symbol === BORROWED_DIRECT_INDEX_ID
  ) {
    return holding.marginRequirement;
  }

  let cMargin;
  if (hVal.lessThan(portfolioValue.mul(CONCENTRATION_MAX))) {
    return holding.marginRequirement;
  } else if (hVal.lessThan(portfolioValue.mul(0.35))) {
    cMargin = 0.35;
  } else if (hVal.lessThan(portfolioValue.mul(0.5))) {
    cMargin = 0.4;
  } else {
    // >= 50%
    cMargin = 0.5;
  }

  return Decimal.max(holding.marginRequirement, cMargin);
};

export const getConcentratedAdjustedMaintenanceRequirement = (
  weight: Decimal,
  maintenanceMarginRequirement: Decimal,
): Decimal => {
  if (weight.lessThan(CONCENTRATION_MAX)) {
    return maintenanceMarginRequirement;
  } else if (weight.lessThan(0.35)) {
    return Decimal.max(maintenanceMarginRequirement, 0.35);
  } else if (weight.lessThan(0.5)) {
    return Decimal.max(maintenanceMarginRequirement, 0.4);
  } else {
    // >= 50%
    return Decimal.max(maintenanceMarginRequirement, 0.5);
  }
};

/**
 * Returns the value of the securities needed to support the specified loan, here the `holdings` are
 * only supplied to determine the blended margin requirement.
 *
 * portfolioValue * (1 - marginReq) >= loanAmount
 * => portfolioValue >= loanAmount/(1 - marginReq)
 *
 * undefined if margin requirement is 100% and loanAmount > 0.
 *
 * Note: Depending on the use case the caller may want to deduct the frecCash from the loan
 * amount before calling this method. For example, when determining whether an
 * account is in a margin call state.
 */
export const getRequiredHoldingsAtLoanAmount = (
  holdings: Holding[],
  loanAmount: Decimal,
  fixedMarginRequirement?: Decimal,
): Decimal | undefined => {
  if (loanAmount.lessThanOrEqualTo(0)) return new Decimal(0);
  const LTV = ONE.minus(
    fixedMarginRequirement || getMarginRequirement(holdings),
  );
  if (LTV.lessThanOrEqualTo(0)) return undefined;

  const baseRequirement = loanAmount.div(LTV);

  // MIN_EQUITY_FOR_LOAN is required to support any loan
  return Decimal.max(baseRequirement, loanAmount.add(MIN_EQUITY_FOR_LOAN));
};

/**
 * Takes a list of `Holding`s and determines the maximum amount that can be borrowed against the
 * entire list (does not take existing loan as input).
 *
 * maxBorrow = portfolioValue * (1 - blendedMarginRequirement)
 *           = portfolioValue * (\sum_i holdingValue_i * (1 - marginRequirement_i)) / \sum_i holdingValue_i
 *           = portfolioValue * (\sum_i holdingValue_i * (1 - marginRequirement_i)) / portfolioValue
 *           = \sum_i holdingValue_i * (1 - marginRequirement_i)
 *
 * Note: borrow amounts ignore any cash amount in the account.
 */
export const getMaxBorrowAmount = (args: {
  holdings: Holding[];
  fixedMarginRequirement?: Decimal;
}): Decimal => {
  const { holdings, fixedMarginRequirement } = args;

  const value = getSecurityHoldingsValue(holdings);
  if (value.lessThanOrEqualTo(0)) return new Decimal(0);

  const marginReq =
    fixedMarginRequirement === undefined
      ? getMarginRequirement(holdings)
      : fixedMarginRequirement;

  // (1 - MR) * value = maxBorrowingPower
  const baseBP = marginReq.neg().add(1).mul(value);

  // Since we require EQUITY > X for loans, BP can be capped.
  return Decimal.max(0, Decimal.min(baseBP, value.sub(MIN_EQUITY_FOR_LOAN)));
};

/**
 * Given existing borrowing power, and new assets (as part of combined holdings),
 * returns the new approximate borrowing power.
 */
export const getApproximateBorrowingPower = (args: {
  selectedHoldingsValue: Decimal;
  currentBorrowingPower: Decimal;
  combinedHoldings: Holding[];
  existingLoan: Decimal;
  useSMA?: boolean;
}): Decimal => {
  const frecBorrowingPower = getMaxBorrowAmount({
    holdings: args.combinedHoldings,
  }).sub(args.existingLoan);
  // when we're running validations on a margin loan transfer, we should ignore
  // approximate SMA estimation, as they're likely too low
  if (args.useSMA === false) {
    return frecBorrowingPower;
  }

  const newSMA = args.selectedHoldingsValue.mul(ASSUMED_LTV_OF_ACAT);
  const combinedSMA = args.currentBorrowingPower.add(newSMA);
  return Decimal.min(combinedSMA, frecBorrowingPower);
};

/**
 * Returns the value-weighted margin requirement represented as a number between (0,1)
 * i.e. 0.25 => 25% margin requirement.
 */
export const getMarginRequirement = (holdings: Holding[]): Decimal => {
  const value = getSecurityHoldingsValue(holdings);
  const baseBP = holdings.reduce(
    (prev, curr) =>
      prev.add(
        curr.price.mul(
          curr.quantity.mul(getConcentratedAdjustedMargin(curr, value)),
        ),
      ),
    new Decimal(0),
  );

  return baseBP.div(value);
};

/**
 * Returns true if a concentrated position exists in a set of holdings.
 */
export const existsConcentratedHolding = (holdings: Holding[]): boolean => {
  const cValue = getSecurityHoldingsValue(holdings).mul(CONCENTRATION_MAX);
  const holding = holdings.find((h) =>
    h.price.mul(h.quantity).greaterThanOrEqualTo(cValue),
  );
  return holding !== undefined;
};

/**
 * Returns the amount of stock a user would have to sell to cover a margin call.
 * https://www.notion.so/frec/Margin-Call-Calculation-bc47192e4b3444f2bce198f41e1e94c3#7226d8535aa041b7a6e3188e3b5cac4e
 *
 * If not in margin call: return 0
 * If holdings can't pay off loan (i.e. holdingValue < loan): return undefined
 * If holdings - loan < MIN_EQUITY_FOR_LOAN: return loan
 */
export const getStockValueToSellToCoverMarginCall = (args: {
  loanAmount: Decimal | number;
  holdings: Holding[];
}): Decimal | undefined => {
  const { holdings } = args;
  const l = makeDecimal(args.loanAmount);

  const p = getSecurityHoldingsValue(holdings);
  if (p.lessThan(l)) return undefined;

  if (p.sub(l).lt(MIN_EQUITY_FOR_LOAN)) return l;

  const m = getMarginRequirement(holdings);
  const maxBorrow = ONE.minus(m).mul(p);
  if (maxBorrow.greaterThanOrEqualTo(l)) return new Decimal(0);

  return l.minus(maxBorrow).div(m);
};

/**
 * Important: We do not use frecCash in the equity calculation here, because for the
 * purpose of borrowing cash is not used as collateral. For other purposes, for example
 * determining if the user is in margin call, you may want to add cash.
 */
export const getEquityValuesNoCash = (args: {
  holdings: Holding[];
  loanAmount: Decimal | number;
}) => {
  const { holdings } = args;
  const loanAmount = makeDecimal(args.loanAmount);
  const holdingValue = getSecurityHoldingsValue(holdings);
  const equity = holdingValue.sub(loanAmount);
  const maxBorrow = getMaxBorrowAmount({ holdings });
  const excessEquity = maxBorrow.sub(loanAmount);

  return { equity, excessEquity };
};

export const getEquityValuesWithCash = (args: {
  holdings: Holding[];
  loanAmount: Decimal | number;
  frecCash: Decimal | number;
}) => {
  const { equity, excessEquity } = getEquityValuesNoCash(args);
  return {
    equity: equity.add(args.frecCash),
    excessEquity: excessEquity.add(args.frecCash),
  };
};

/**
 * This is just negative excess equity, but we use this function to keep external
 * code from having to do any math (avoiding mistakes, hopefully).
 *
 * This function assumes frecCash is zero (rebalance calls).
 */
export const getCashValueToCoverMarginCall = (args: {
  loanAmount: Decimal | number;
  holdings: Holding[];
}): Decimal => {
  // Negative excessEquity means we're in margin call territory.
  const { excessEquity } = getEquityValuesNoCash(args);
  return Decimal.max(0, excessEquity.neg());
};

/**
 * Used to determine whether a portfolio is in margin call, rebalance call, or
 * in good standing.
 */
export const getAccountState = (args: {
  holdings: Holding[];
  loanAmount: Decimal | number;
  cash: Decimal | number;
}):
  | { accountState: MarginAccountState.GoodStanding; callAmount: null }
  | {
      accountState:
        | MarginAccountState.MarginCall
        | MarginAccountState.RebalanceCall;
      callAmount: Decimal;
    } => {
  const { holdings } = args;
  const cash = makeDecimal(args.cash);
  const loanAmount = makeDecimal(args.loanAmount);

  const { excessEquity } = getEquityValuesNoCash({ holdings, loanAmount });

  if (excessEquity.gte(0)) {
    return {
      accountState: MarginAccountState.GoodStanding,
      callAmount: null,
    };
  }

  // excessEquity is negative at this point, so negate it
  const equityShortfall = excessEquity.neg();

  // If we can cover shortfall w/ available frec cash, then rebalance.
  if (equityShortfall.lte(cash)) {
    return {
      accountState: MarginAccountState.RebalanceCall,
      callAmount: equityShortfall,
    };
  }

  // We margin call only the amount that exceeds cash.
  const marginCallAmount = equityShortfall.sub(cash);
  return {
    accountState: MarginAccountState.MarginCall,
    callAmount: marginCallAmount,
  };
};

/**
 * Debugging function
 */
export const printHoldings = (holdings: Holding[], desc?: string): void => {
  const value = getSecurityHoldingsValue(holdings);

  let str = desc ?? "";
  str += ` value: ${value.toFixed(2)} maxBorrow: ${getMaxBorrowAmount({
    holdings,
  }).toFixed(2)}\n`;
  for (const h of holdings) {
    str += `${h.symbol}\t q: ${h.quantity.toFixed(2)}\t p: ${h.price.toFixed(
      2,
    )}\t v:${h.price
      .mul(h.quantity)
      .toFixed(2)}\t mr: ${h.marginRequirement.toFixed(
      2,
    )}\t cmr: ${getConcentratedAdjustedMargin(h, value)}\n`;
  }
  // eslint-disable-next-line no-console
  console.log(str);
};

/**
 * Splits an amount into borrow and deposit parts based on a borrow percentage.
 * @param amount total amount to split into borrow and deposit parts
 * @param borrowPercent 0 to 100, the percentage of the amount to borrow
 */
export const splitAmountIntoBorrowAndDeposit = (
  amount: Decimal,
  borrowPercent: number,
) => {
  // Round down borrow part to 2 decimal places so odd decimals can be split properly
  const borrow = amount
    .mul(borrowPercent / 100)
    .toDecimalPlaces(2, Decimal.ROUND_DOWN);
  return {
    borrow,
    deposit: amount.sub(borrow),
  };
};

/**
 * Calculates the maximum percentage of a direct index investment that can be borrowed
 * from a portfolio line of credit. Returns a number (that is a percentage) from 0 to 100.
 */
export const getMaxBorrowPercent = (
  investAmount: Decimal,
  currentBorrowingPower: Decimal,
  directIndexLTV: Decimal,
): number => {
  if (investAmount.lessThanOrEqualTo(0)) {
    return 100;
  }

  const additionalBorrowingPower = investAmount.mul(directIndexLTV);
  const totalBorrowingPower = currentBorrowingPower.add(
    additionalBorrowingPower,
  );
  return Decimal.min(
    1,
    totalBorrowingPower
      .div(investAmount)
      .toDecimalPlaces(2, Decimal.ROUND_DOWN),
  )
    .mul(100)
    .toNumber();
};
