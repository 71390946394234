import Decimal from "decimal.js";

import {
  DirectIndexType,
  SectorMapping,
  Security,
  SubAccount,
} from "../generated/graphql";

export type DirectIndexingCustomization = {
  addedSecurities: Security[];
  excludedSectors: SectorMapping[];
  excludedSecurities: Security[];
};

export type DirectIndexSubAccount = {
  type: DirectIndexType;
  subAccount: SubAccount;
  subAccountId: string;
  signedAt?: Date;
  customization: DirectIndexingCustomization;
  lhfOverride?: Decimal;
};

export type DirectIndexingSetupStockSelection = {
  securityId: string;
  symbol: string;
  name: string;
  quantity: Decimal;
  value: Decimal;
  withinIndex: boolean;
  targetAllocation?: Decimal;
  canShowTargetAllocation: boolean;
  taxLots: Array<{
    id: string;
    quantity: Decimal;
    openBuyPrice: Decimal;
  }>;
};

export enum DirectIndexingSetupDepositMethod {
  Cash = "Cash",
  StocksAndCash = "StocksAndCash",
  LineOfCredit = "LineOfCredit",
}
