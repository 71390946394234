import { DirectIndexType } from "../generated/graphql";

enum DirectIndexingIconSvgName {
  CRSPMidCapIcon = "building-office.svg",
  Russell3000Icon = "chart-donut.svg",
  SmhIcon = "cpu.svg",
  SPAdrDmIcon = "globe.svg",
  SP500Icon = "s&p500.svg",
  SP500InfoTechIcon = "s&pInfoTech.svg",
  SPShariahIcon = "scales.svg",
  CRSPSmallCapIcon = "shop.svg",
  CRSPIssLargeCapEsgIcon = "tree.svg",
  SP500 = "s&p500.svg",
}

export const DirectIndexingIconNameMap = new Map<
  DirectIndexType,
  DirectIndexingIconSvgName
>([
  [DirectIndexType.Sp500, DirectIndexingIconSvgName.SP500Icon],
  [DirectIndexType.SpInfoTech, DirectIndexingIconSvgName.SP500InfoTechIcon],
  [DirectIndexType.CrspMidCap, DirectIndexingIconSvgName.CRSPMidCapIcon],
  [DirectIndexType.CrspSmallCap, DirectIndexingIconSvgName.CRSPSmallCapIcon],
  [DirectIndexType.CrspLargeCap, DirectIndexingIconSvgName.SP500Icon],
  [
    DirectIndexType.CrspIssLargeCapEsg,
    DirectIndexingIconSvgName.CRSPIssLargeCapEsgIcon,
  ],
  [DirectIndexType.CrspTotalMarket, DirectIndexingIconSvgName.Russell3000Icon],
  [DirectIndexType.SpShariah, DirectIndexingIconSvgName.SPShariahIcon],
  [DirectIndexType.Russell_1000, DirectIndexingIconSvgName.Russell3000Icon],
  [DirectIndexType.Russell_2000, DirectIndexingIconSvgName.CRSPSmallCapIcon],
  [DirectIndexType.Russell_3000, DirectIndexingIconSvgName.Russell3000Icon],
  [DirectIndexType.SpAdrDm, DirectIndexingIconSvgName.SPAdrDmIcon],
  [DirectIndexType.SpAdrEm, DirectIndexingIconSvgName.SPAdrDmIcon],
  [DirectIndexType.Smh, DirectIndexingIconSvgName.SmhIcon],
]);
