export const classNames = (...classes: (string | undefined)[]) => {
  return classes.filter(Boolean).join(" ");
};

export const mergeClassNames = (
  originalClassNames: Record<string, string>,
  newClassNames?: Record<string, string>,
) => {
  const results = { ...originalClassNames };
  Object.entries(newClassNames ?? {}).forEach(([key, value]) => {
    results[key] = `${results[key] ?? ""} ${value ?? ""}`;
  });
  return results;
};
