import Decimal from "decimal.js";

import { ValidationResultLevel } from "../common/enums";
import { ACH_DAILY_TRANSFER_LIMIT } from "../constants/cashTransfer";
import {
  DI_ONBOARDING_PRICE_FLUCTUATION_BUFFER,
  DI_SUPPORTED_LIQUIDATION_DESTINATIONS,
  DirectIndexingTypeShortText,
  DirectIndexingTypeText,
} from "../constants/directIndex";
import {
  CashTransferDirection,
  CashTransferMethod,
  DirectIndexType,
  GicsCode,
  MoneyMovementSourceType,
} from "../generated/graphql";
import { splitAmountIntoBorrowAndDeposit } from "../portfolio_utils";
import { formatUsd, ZERO } from "../utils";
import {
  CreateCashTransferValidationErrorMessage,
  CreateCashTransferValidationResult,
  validateExternalCashTransfer,
} from "./cashTransfer";

export enum SubAccountCashTransferValidationResult {
  InsufficientCash = "InsufficientCash",
  AmountShouldBeGreaterThanZero = "AmountShouldBeGreaterThanZero",
}

export const SubAccountCashTransferValidationResultLevelMap: Record<
  SubAccountCashTransferValidationResult,
  ValidationResultLevel
> = {
  InsufficientCash: ValidationResultLevel.Error,
  AmountShouldBeGreaterThanZero: ValidationResultLevel.Error,
};

export type SubAccountCashTransferArgs = {
  amount: Decimal;
  cashAvailable: Decimal;
};

export const validateSubAccountCashTransfer = (
  args: SubAccountCashTransferArgs,
) => {
  const results = new Set<SubAccountCashTransferValidationResult>();

  if (args.amount.lessThanOrEqualTo(0)) {
    results.add(
      SubAccountCashTransferValidationResult.AmountShouldBeGreaterThanZero,
    );
  }

  if (args.amount.greaterThan(args.cashAvailable)) {
    results.add(SubAccountCashTransferValidationResult.InsufficientCash);
  }

  return Array.from(results);
};

export enum LiquidatePortfolioValidationResult {
  InsufficientPortfolioValue = "InsufficientPortfolioValue",
  AmountShouldBeGreaterThanZero = "AmountShouldBeGreaterThanZero",
  InvalidLiquidationAmount = "InvalidLiquidationAmount",
  InvalidLiquidationDestination = "InvalidLiquidationDestination",
}

export const LiquidatePortfolioValidationResultLevelMap: Record<
  LiquidatePortfolioValidationResult,
  ValidationResultLevel
> = {
  InsufficientPortfolioValue: ValidationResultLevel.Error,
  AmountShouldBeGreaterThanZero: ValidationResultLevel.Error,
  InvalidLiquidationAmount: ValidationResultLevel.Error,
  InvalidLiquidationDestination: ValidationResultLevel.Error,
};

export type LiquidateDirectIndexPortfolioArgs = {
  liquidateAmount?: Decimal;
  isFullLiquidation?: boolean;
  portfolioValue: Decimal;
  destinationId?: string;
  destinationType?: MoneyMovementSourceType;
};

export enum DirectIndexCustomizationValidationResult {
  ExceedsEditableStocksLimit = "ExceedsEditableStocksLimit",
  ExceedsEditableSectorsLimit = "ExceedsEditableSectorsLimit",
  CannotRemoveSectorsFromSPInfoTech = "CannotRemoveSectorsFromSPInfoTech",
}

export type DirectIndexCustomizationArgs = {
  directIndexType: DirectIndexType;
  removeGICSSectorIds: GicsCode[];
  addSecuritySymbols: string[];
  removeSecuritySymbols: string[];
  editableNumberOfStocks: number;
  editableNumberOfSectors: number;
};

export enum CashTransferToDirectIndexPortfolioValidationResult {
  AmountShouldBeGreaterThanOrEqualToZero = "AmountShouldBeGreaterThanOrEqualToZero",
  InsufficientBalance = "InsufficientBalance",
  InsufficientBorrowingPower = "InsufficientBorrowingPower",
  InvalidAchTransferRequestArgs = "InvalidAchTransferRequestArgs",
  InvalidLoanPercentage = "InvalidLoanPercentage",
  LoanPercentageConflictWithSourceType = "LoanPercentageConflictWithSourceType",
}

export type ValidateCashTransferToDirectIndexPortfolioArgs = {
  amount: Decimal;
  loanPercentage?: Decimal;
  sourceType: MoneyMovementSourceType;
  sourceId?: string;
  balance?: Decimal;
  currentBorrowingPower?: Decimal;
  diOnboarding?: boolean; // Whether the customer is setting up a new DI subaccount or depositting to an existing DI subaccount
  diInitialLTV?: Decimal;
  achDailyTransferLimitOverride?: number;
  currentDayAchDepositTotal?: Decimal;
  depositPlaidAccountId?: string;
};

export type CashTransferToDirectIndexPortfolioValidationResultType =
  | CashTransferToDirectIndexPortfolioValidationResult
  | CreateCashTransferValidationResult;

export const CashTransferToDirectIndexPortfolioErrorMessage: Record<
  CashTransferToDirectIndexPortfolioValidationResultType,
  (
    args: Pick<
      ValidateCashTransferToDirectIndexPortfolioArgs,
      "amount" | "achDailyTransferLimitOverride"
    >,
  ) => string
> = {
  [CashTransferToDirectIndexPortfolioValidationResult.AmountShouldBeGreaterThanOrEqualToZero]:
    () => "Cash transfer amount should be greater than or equal to $0.",
  [CashTransferToDirectIndexPortfolioValidationResult.InsufficientBalance]:
    () =>
      "The account you are transferring from does not have sufficient balance.",
  [CashTransferToDirectIndexPortfolioValidationResult.InsufficientBorrowingPower]:
    () =>
      "You do not have sufficient borrowing power to cover the loan amount.",
  [CashTransferToDirectIndexPortfolioValidationResult.InvalidAchTransferRequestArgs]:
    () => "Invalid ACH transfer request args. Missing sourceId.",
  [CashTransferToDirectIndexPortfolioValidationResult.InvalidLoanPercentage]:
    () =>
      "Invalid loan percentage. It can only be between 0 and 100 inclusive.",
  [CashTransferToDirectIndexPortfolioValidationResult.LoanPercentageConflictWithSourceType]:
    () =>
      "Loan percenrage conflicts with source type. If the source type is LOC, the loan percentage can only be either undefined or 100. If the loan percentage is set, only certain source types are allowed.",
  [CreateCashTransferValidationResult.AmountShouldBeGreaterThanZero]: () =>
    CreateCashTransferValidationErrorMessage[
      CreateCashTransferValidationResult.AmountShouldBeGreaterThanZero
    ],
  [CreateCashTransferValidationResult.InsufficientCash]: () =>
    CreateCashTransferValidationErrorMessage[
      CreateCashTransferValidationResult.InsufficientCash
    ],
  [CreateCashTransferValidationResult.AchDepositAmountShouldBeGreaterThanMin]:
    () =>
      CreateCashTransferValidationErrorMessage[
        CreateCashTransferValidationResult
          .AchDepositAmountShouldBeGreaterThanMin
      ],
  [CreateCashTransferValidationResult.WireWithdrawalAmountShouldBeGreaterThanMin]:
    () =>
      CreateCashTransferValidationErrorMessage[
        CreateCashTransferValidationResult
          .WireWithdrawalAmountShouldBeGreaterThanMin
      ],
  [CreateCashTransferValidationResult.AchDailyLimitBreached]: ({
    achDailyTransferLimitOverride,
    amount,
  }) =>
    `The total amount of ${formatUsd(
      amount,
    )} exceeds the daily limit of ${formatUsd(
      achDailyTransferLimitOverride ?? ACH_DAILY_TRANSFER_LIMIT,
    )} for ACH transfers. You can wire instead.`,
  [CreateCashTransferValidationResult.NotAllowed]: () =>
    CreateCashTransferValidationErrorMessage[
      CreateCashTransferValidationResult.NotAllowed
    ],
  [CreateCashTransferValidationResult.InvalidWireRoutingNumber]: () =>
    CreateCashTransferValidationErrorMessage[
      CreateCashTransferValidationResult.InvalidWireRoutingNumber
    ],
  [CreateCashTransferValidationResult.UnsupportedCashTransferMethod]: () =>
    CreateCashTransferValidationErrorMessage[
      CreateCashTransferValidationResult.UnsupportedCashTransferMethod
    ],
  [CreateCashTransferValidationResult.UnsupportedCashTransferAccountDirection]:
    () =>
      CreateCashTransferValidationErrorMessage[
        CreateCashTransferValidationResult
          .UnsupportedCashTransferAccountDirection
      ],
  [CreateCashTransferValidationResult.UnsupportedFullLiquidation]: () =>
    CreateCashTransferValidationErrorMessage[
      CreateCashTransferValidationResult.UnsupportedFullLiquidation
    ],
  [CreateCashTransferValidationResult.LoanAmountShouldBeGreaterThanMin]: () =>
    CreateCashTransferValidationErrorMessage[
      CreateCashTransferValidationResult.LoanAmountShouldBeGreaterThanMin
    ],
};

// TODO(FREC-4343): Consider calling runInitiateLoanValidations() as its
// validations are more comprehensive.
export const validateCashTransferToDirectIndexPortfolio = (
  args: ValidateCashTransferToDirectIndexPortfolioArgs,
) => {
  const results =
    new Set<CashTransferToDirectIndexPortfolioValidationResultType>();
  if (args.amount.lte(0)) {
    results.add(
      CashTransferToDirectIndexPortfolioValidationResult.AmountShouldBeGreaterThanOrEqualToZero,
    );
  }
  // Checks some assumption about loanPercentage.
  if (args.loanPercentage?.lt(0) || args.loanPercentage?.gt(100)) {
    results.add(
      CashTransferToDirectIndexPortfolioValidationResult.InvalidLoanPercentage,
    );
  }
  if (
    args.sourceType === MoneyMovementSourceType.LineOfCredit &&
    args.loanPercentage &&
    !args.loanPercentage.eq(100)
  ) {
    results.add(
      CashTransferToDirectIndexPortfolioValidationResult.LoanPercentageConflictWithSourceType,
    );
  }
  if (
    args.loanPercentage?.eq(100) &&
    args.sourceType !== MoneyMovementSourceType.LineOfCredit
  ) {
    results.add(
      CashTransferToDirectIndexPortfolioValidationResult.LoanPercentageConflictWithSourceType,
    );
  }
  if (
    args.loanPercentage?.gt(0) &&
    ![
      MoneyMovementSourceType.Ach,
      MoneyMovementSourceType.Wire,
      MoneyMovementSourceType.FrecCash,
      MoneyMovementSourceType.LineOfCredit,
    ].includes(args.sourceType)
  ) {
    results.add(
      CashTransferToDirectIndexPortfolioValidationResult.LoanPercentageConflictWithSourceType,
    );
  }

  // Checks the amount against balance and borrowing power.
  const { borrow, deposit } = splitAmountIntoBorrowAndDeposit(
    args.amount,
    args.sourceType === MoneyMovementSourceType.LineOfCredit
      ? 100
      : (args.loanPercentage?.toNumber() ?? 0),
  );
  if (args.balance && deposit.gt(args.balance)) {
    results.add(
      CashTransferToDirectIndexPortfolioValidationResult.InsufficientBalance,
    );
  }
  if (
    args.sourceType === MoneyMovementSourceType.LineOfCredit ||
    args.loanPercentage?.gt(0)
  ) {
    const ltv = args.diInitialLTV ? args.diInitialLTV : ZERO;
    const additionalBorrowingPower = args.diOnboarding
      ? args.amount.mul(ltv)
      : ZERO;
    const currentBorrowingPower = args.currentBorrowingPower
      ? args.currentBorrowingPower
      : ZERO;
    if (borrow.gt(currentBorrowingPower.add(additionalBorrowingPower))) {
      results.add(
        CashTransferToDirectIndexPortfolioValidationResult.InsufficientBorrowingPower,
      );
    }
  }

  if (args.sourceType === MoneyMovementSourceType.Ach) {
    if (!args.sourceId && !args.depositPlaidAccountId) {
      results.add(
        CashTransferToDirectIndexPortfolioValidationResult.InvalidAchTransferRequestArgs,
      );
    }
    const externalCashTransferResults = validateExternalCashTransfer({
      amount: deposit,
      method: CashTransferMethod.Ach,
      amountAvailableToWithdraw: ZERO, // not withdrawing
      direction: CashTransferDirection.Deposit, // deposit only
      achDailyTransferLimitOverride:
        args.achDailyTransferLimitOverride ?? ACH_DAILY_TRANSFER_LIMIT,
      currentDayAchDepositTotal: args.currentDayAchDepositTotal ?? ZERO,
      currentDayAchWithdrawalTotal: ZERO, // not withdrawing
      isForBorrow: false,
      isAllowed: true,
    });
    externalCashTransferResults.forEach((result) => results.add(result));
  }
  return Array.from(results);
};

// Create direct index sub account arg types
export enum CreateDirectIndexSubAccountValidationResult {
  AmountShouldBeGreaterThanZero = "AmountShouldBeGreaterThanZero",
  AmountShouldBeAtLeastMiniumCashOnly = "AmountShouldBeAtLeastMiniumCashOnly",
  AmountShouldBeAtLeastMiniumCashAndStocks = "AmountShouldBeAtLeastMiniumCashAndStocks",
  OverlappingDirectIndicesExist = "OverlappingDirectIndicesExist",
  UndefinedSourceType = "UndefinedSourceType",
}

export type ValidateCreateDirectIndexSubAccountArgs = {
  sourceType?: MoneyMovementSourceType;
  sourceId?: string;
  directIndexType: DirectIndexType;
  minimumAmount: number;
  amountBuffer?: Decimal;
  amount: Decimal; // amount not coming from stocks
  loanPercentage?: Decimal;
  stockValue: Decimal; // total value of stocks, can be 0
  balance?: Decimal; // balance of account the amount is coming from
  overlappingDirectIndices: DirectIndexType[];
  customization?: DirectIndexCustomizationArgs;

  // ACH specific args
  achDailyTransferLimitOverride?: number;
  currentDayAchDepositTotal?: Decimal;

  // PLOC args
  currentBorrowingPower?: Decimal;
  diInitialLTV?: Decimal;
};

export type CreateDirectIndexSubAccountValidationResultType =
  | CreateDirectIndexSubAccountValidationResult
  | DirectIndexCustomizationValidationResult
  | CreateCashTransferValidationResult
  | CashTransferToDirectIndexPortfolioValidationResult;

export const CreateDirectIndexSubAccountValidationErrorMessage: Record<
  CreateDirectIndexSubAccountValidationResultType,
  (args: ValidateCreateDirectIndexSubAccountArgs) => string
> = {
  [CreateDirectIndexSubAccountValidationResult.AmountShouldBeGreaterThanZero]:
    () => "Deposit amount should be greater than $0.",
  [CreateDirectIndexSubAccountValidationResult.AmountShouldBeAtLeastMiniumCashOnly]:
    ({ directIndexType, minimumAmount }) =>
      `The minimum amount to invest is ${formatUsd(minimumAmount)} for the ${
        DirectIndexingTypeText[directIndexType]
      } index.`,
  [CreateDirectIndexSubAccountValidationResult.AmountShouldBeAtLeastMiniumCashAndStocks]:
    ({ directIndexType, stockValue, minimumAmount }) =>
      `The minimum additional cash to deposit is ${formatUsd(
        stockValue.minus(minimumAmount).abs(),
        0,
        2,
      )} for the ${DirectIndexingTypeText[directIndexType]} index.`,
  [CreateDirectIndexSubAccountValidationResult.OverlappingDirectIndicesExist]:
    ({ overlappingDirectIndices }) =>
      `The positions within this index overlap significantly with one or more of your existing direct indices: ${overlappingDirectIndices
        .map((type) => DirectIndexingTypeShortText[type])
        .join(", ")}.`,
  [CreateDirectIndexSubAccountValidationResult.UndefinedSourceType]: () =>
    "A source of funds must be specified when depositing cash.",
  [DirectIndexCustomizationValidationResult.ExceedsEditableStocksLimit]: ({
    customization,
  }) =>
    `The number of stocks you added or excluded exceeds the limit of ${customization?.editableNumberOfStocks}.`,
  [DirectIndexCustomizationValidationResult.ExceedsEditableSectorsLimit]: ({
    customization,
  }) =>
    `The number of sectors you excluded exceeds the limit of ${customization?.editableNumberOfSectors}.`,
  [DirectIndexCustomizationValidationResult.CannotRemoveSectorsFromSPInfoTech]:
    () =>
      `You cannot exclude sectors from the ${
        DirectIndexingTypeShortText[DirectIndexType.SpInfoTech]
      } index.`,
  [CashTransferToDirectIndexPortfolioValidationResult.AmountShouldBeGreaterThanOrEqualToZero]:
    (args) =>
      CashTransferToDirectIndexPortfolioErrorMessage[
        CashTransferToDirectIndexPortfolioValidationResult
          .AmountShouldBeGreaterThanOrEqualToZero
      ](args),
  [CashTransferToDirectIndexPortfolioValidationResult.InsufficientBalance]: (
    args,
  ) =>
    CashTransferToDirectIndexPortfolioErrorMessage[
      CashTransferToDirectIndexPortfolioValidationResult.InsufficientBalance
    ](args),
  [CashTransferToDirectIndexPortfolioValidationResult.InsufficientBorrowingPower]:
    (args) =>
      CashTransferToDirectIndexPortfolioErrorMessage[
        CashTransferToDirectIndexPortfolioValidationResult
          .InsufficientBorrowingPower
      ](args),
  [CashTransferToDirectIndexPortfolioValidationResult.InvalidAchTransferRequestArgs]:
    (args) =>
      CashTransferToDirectIndexPortfolioErrorMessage[
        CashTransferToDirectIndexPortfolioValidationResult
          .InvalidAchTransferRequestArgs
      ](args),
  [CashTransferToDirectIndexPortfolioValidationResult.InvalidLoanPercentage]: (
    args,
  ) =>
    CashTransferToDirectIndexPortfolioErrorMessage[
      CashTransferToDirectIndexPortfolioValidationResult.InvalidLoanPercentage
    ](args),
  [CashTransferToDirectIndexPortfolioValidationResult.LoanPercentageConflictWithSourceType]:
    (args) =>
      CashTransferToDirectIndexPortfolioErrorMessage[
        CashTransferToDirectIndexPortfolioValidationResult
          .LoanPercentageConflictWithSourceType
      ](args),
  [CreateCashTransferValidationResult.InsufficientCash]: () =>
    CreateCashTransferValidationErrorMessage[
      CreateCashTransferValidationResult.InsufficientCash
    ],
  [CreateCashTransferValidationResult.AchDepositAmountShouldBeGreaterThanMin]:
    () =>
      CreateCashTransferValidationErrorMessage[
        CreateCashTransferValidationResult
          .AchDepositAmountShouldBeGreaterThanMin
      ],
  [CreateCashTransferValidationResult.WireWithdrawalAmountShouldBeGreaterThanMin]:
    () =>
      CreateCashTransferValidationErrorMessage[
        CreateCashTransferValidationResult
          .WireWithdrawalAmountShouldBeGreaterThanMin
      ],
  [CreateCashTransferValidationResult.AchDailyLimitBreached]: ({
    achDailyTransferLimitOverride,
    amount,
    loanPercentage,
  }) =>
    `The deposit amount of ${formatUsd(
      splitAmountIntoBorrowAndDeposit(amount, loanPercentage?.toNumber() ?? 0)
        .deposit,
    )} exceeds the daily limit of ${formatUsd(
      achDailyTransferLimitOverride ?? ACH_DAILY_TRANSFER_LIMIT,
    )} for ACH transfers. You can wire instead.`,
  [CreateCashTransferValidationResult.NotAllowed]: () =>
    CreateCashTransferValidationErrorMessage[
      CreateCashTransferValidationResult.NotAllowed
    ],
  [CreateCashTransferValidationResult.InvalidWireRoutingNumber]: () =>
    CreateCashTransferValidationErrorMessage[
      CreateCashTransferValidationResult.InvalidWireRoutingNumber
    ],
  [CreateCashTransferValidationResult.UnsupportedCashTransferMethod]: () =>
    CreateCashTransferValidationErrorMessage[
      CreateCashTransferValidationResult.UnsupportedCashTransferMethod
    ],
  [CreateCashTransferValidationResult.UnsupportedCashTransferAccountDirection]:
    () =>
      CreateCashTransferValidationErrorMessage[
        CreateCashTransferValidationResult
          .UnsupportedCashTransferAccountDirection
      ],
  [CreateCashTransferValidationResult.UnsupportedFullLiquidation]: () =>
    CreateCashTransferValidationErrorMessage[
      CreateCashTransferValidationResult.UnsupportedFullLiquidation
    ],
  [CreateCashTransferValidationResult.LoanAmountShouldBeGreaterThanMin]: () =>
    CreateCashTransferValidationErrorMessage[
      CreateCashTransferValidationResult.LoanAmountShouldBeGreaterThanMin
    ],
};

export const validateDirectIndexPortfolioLiquidate = (
  args: LiquidateDirectIndexPortfolioArgs,
) => {
  const results = new Set<LiquidatePortfolioValidationResult>();

  // one of isFullLiquidation and liquidateAmount needs to be set
  if (
    (args.isFullLiquidation && args.liquidateAmount) ||
    (!args.liquidateAmount && !args.isFullLiquidation)
  ) {
    results.add(LiquidatePortfolioValidationResult.InvalidLiquidationAmount);
  }

  if (args.liquidateAmount?.lessThanOrEqualTo(0)) {
    results.add(
      LiquidatePortfolioValidationResult.AmountShouldBeGreaterThanZero,
    );
  }

  if (args.liquidateAmount?.greaterThan(args.portfolioValue)) {
    results.add(LiquidatePortfolioValidationResult.InsufficientPortfolioValue);
  }

  if (
    (args.destinationId && !args.destinationType) ||
    (!args.destinationId && args.destinationType)
  ) {
    results.add(
      LiquidatePortfolioValidationResult.InvalidLiquidationDestination,
    );
  }

  if (
    args.destinationType &&
    !DI_SUPPORTED_LIQUIDATION_DESTINATIONS.has(args.destinationType)
  ) {
    results.add(
      LiquidatePortfolioValidationResult.InvalidLiquidationDestination,
    );
  }
  return Array.from(results);
};

export const validateDirectIndexCustomization = (
  args: DirectIndexCustomizationArgs,
) => {
  const results = new Set<DirectIndexCustomizationValidationResult>();

  // check number of sectors edited
  if (args.removeGICSSectorIds.length > args.editableNumberOfSectors) {
    results.add(
      DirectIndexCustomizationValidationResult.ExceedsEditableSectorsLimit,
    );
  }

  // check number of stocks edited
  if (
    args.addSecuritySymbols.length + args.removeSecuritySymbols.length >
    args.editableNumberOfStocks
  ) {
    results.add(
      DirectIndexCustomizationValidationResult.ExceedsEditableStocksLimit,
    );
  }

  // cannot remove sectors from info tech
  if (
    args.directIndexType === DirectIndexType.SpInfoTech &&
    args.removeGICSSectorIds.length > 0
  ) {
    results.add(
      DirectIndexCustomizationValidationResult.CannotRemoveSectorsFromSPInfoTech,
    );
  }

  return Array.from(results);
};

/**
 * Common validations for creating a direct index sub account.
 *
 * Checks for:
 * - minimum deposit amount
 * - sufficient balance or borrowing power to cover cash transfer
 * - sub account cash transfer validation
 * - external cash transfer validation
 * - valid customizations
 * - overlapping direct indices
 *
 * Does not check for:
 * - direct index sub account already exists
 */
export const validateCreateDirectIndexSubAccount = (
  args: ValidateCreateDirectIndexSubAccountArgs,
) => {
  const results = new Set<CreateDirectIndexSubAccountValidationResultType>();

  // Negative checks
  if (args.amount.lt(0) || args.stockValue.lt(0)) {
    results.add(
      CreateDirectIndexSubAccountValidationResult.AmountShouldBeGreaterThanZero,
    );
  }

  // If amount is greater than 0, then sourceType must be defined
  if (args.amount.gt(0) && !args.sourceType && !args.loanPercentage?.eq(100)) {
    results.add(
      CreateDirectIndexSubAccountValidationResult.UndefinedSourceType,
    );
  }

  // Minimum deposit amount check
  if (args.stockValue.isZero() && args.amount.lt(args.minimumAmount)) {
    // cash only case
    results.add(
      CreateDirectIndexSubAccountValidationResult.AmountShouldBeAtLeastMiniumCashOnly,
    );
  }
  // TODO: fix the rounding issue by consistently rounding in frontend and here
  const totalDeposit = args.amount.add(args.stockValue);
  if (
    args.stockValue.gt(0) &&
    totalDeposit.lt(args.minimumAmount) &&
    totalDeposit
      .minus(args.minimumAmount)
      .abs()
      .gt(args.amountBuffer ?? DI_ONBOARDING_PRICE_FLUCTUATION_BUFFER)
  ) {
    // cash + stocks
    results.add(
      CreateDirectIndexSubAccountValidationResult.AmountShouldBeAtLeastMiniumCashAndStocks,
    );
  }

  // Validate cash transfer to DI portfolio.
  if (args.amount.gt(0) && args.sourceType) {
    const validateCashTransferToDIResults =
      validateCashTransferToDirectIndexPortfolio({
        amount: args.amount,
        loanPercentage: args.loanPercentage,
        sourceType: args.sourceType,
        sourceId: args.sourceId,
        balance: args.balance,
        currentBorrowingPower: args.currentBorrowingPower,
        diOnboarding: true,
        diInitialLTV: args.diInitialLTV,
        achDailyTransferLimitOverride: args.achDailyTransferLimitOverride,
        currentDayAchDepositTotal: args.currentDayAchDepositTotal,
      });
    validateCashTransferToDIResults.forEach((result) => results.add(result));
  }

  // Customization check
  if (args.customization) {
    const customizationResults = validateDirectIndexCustomization(
      args.customization,
    );
    customizationResults.forEach((result) => results.add(result));
  }

  // Overlapping direct indices check
  if (args.overlappingDirectIndices.length > 0) {
    results.add(
      CreateDirectIndexSubAccountValidationResult.OverlappingDirectIndicesExist,
    );
  }

  return Array.from(results);
};
