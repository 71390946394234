import { IndexConstituent } from "../generated/graphql";

export type IndexMetadataPosition = Omit<
  IndexConstituent,
  "effectiveDate" | "price" | "marketCap"
>;

export enum StockChartPeriod {
  "1D" = "1D",
  "1W" = "1W",
  "1M" = "1M",
  "3M" = "3M",
  "6M" = "6M",
  "1Y" = "1Y",
  "5Y" = "5Y",
  ALL = "ALL",
}
