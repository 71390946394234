import { DefaultPrivacyLevel } from "@datadog/browser-core";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";

const DD_SERVICE = "web";

const ddEnv = () => {
  return {
    NEXT_PUBLIC_REACT_APP_DATADOG_CLIENT_TOKEN:
      process.env.NEXT_PUBLIC_REACT_APP_DATADOG_CLIENT_TOKEN,
    NEXT_PUBLIC_REACT_APP_DATADOG_SITE:
      process.env.NEXT_PUBLIC_REACT_APP_DATADOG_SITE,
    NEXT_PUBLIC_REACT_APP_DATADOG_APPLICATION_ID:
      process.env.NEXT_PUBLIC_REACT_APP_DATADOG_APPLICATION_ID,
    NEXT_PUBLIC_REACT_APP_APPLICATION_ENVIRONMENT:
      process.env.NEXT_PUBLIC_REACT_APP_APPLICATION_ENVIRONMENT,
    NEXT_PUBLIC_REACT_APP_GIT_SHA: process.env.NEXT_PUBLIC_REACT_APP_GIT_SHA,
  };
};

export const initialize = () => {
  const {
    NEXT_PUBLIC_REACT_APP_DATADOG_CLIENT_TOKEN,
    NEXT_PUBLIC_REACT_APP_DATADOG_SITE,
    NEXT_PUBLIC_REACT_APP_DATADOG_APPLICATION_ID,
    NEXT_PUBLIC_REACT_APP_APPLICATION_ENVIRONMENT,
    NEXT_PUBLIC_REACT_APP_GIT_SHA,
  } = ddEnv();

  if (
    NEXT_PUBLIC_REACT_APP_DATADOG_SITE &&
    NEXT_PUBLIC_REACT_APP_DATADOG_CLIENT_TOKEN &&
    NEXT_PUBLIC_REACT_APP_DATADOG_APPLICATION_ID
  ) {
    configureLogging(
      NEXT_PUBLIC_REACT_APP_DATADOG_SITE,
      NEXT_PUBLIC_REACT_APP_DATADOG_CLIENT_TOKEN,
      NEXT_PUBLIC_REACT_APP_DATADOG_APPLICATION_ID,
      NEXT_PUBLIC_REACT_APP_APPLICATION_ENVIRONMENT,
      NEXT_PUBLIC_REACT_APP_GIT_SHA,
    );
    configureRUM(
      NEXT_PUBLIC_REACT_APP_DATADOG_SITE,
      NEXT_PUBLIC_REACT_APP_DATADOG_CLIENT_TOKEN,
      NEXT_PUBLIC_REACT_APP_DATADOG_APPLICATION_ID,
      NEXT_PUBLIC_REACT_APP_APPLICATION_ENVIRONMENT,
      NEXT_PUBLIC_REACT_APP_GIT_SHA,
    );
  } else {
    console.error("DataDog initialization skipped");
  }
};

const configureLogging = (
  site: string,
  clientToken: string,
  applicationId: string,
  env?: string,
  version?: string,
) => {
  datadogLogs.init({
    clientToken,
    site,
    env,
    version,
    service: DD_SERVICE,
    forwardErrorsToLogs: true,

    // sample rates
    telemetrySampleRate: 100,
    sessionSampleRate: 100,
    telemetryConfigurationSampleRate: 100,
    // end sample rates

    forwardConsoleLogs: "all",
    beforeSend: (res) => {
      /**
       * 06/10/2023: Double-clicking a link will throw this error as it cancels the first request
       */
      if (
        typeof res.error?.message === "string" &&
        res.error.message.includes("Loading initial props cancelled")
      ) {
        return false;
      }
      /**
       * 06/10/2023: We see network errors in the logs, but they are not actionable
       */
      if (res.error?.kind === "NetworkError") {
        return false;
      }
      /**
       * FREC-2528: amplitude fetch errors are common for what could be several reasons,
       * but we don't care about them
       */
      if (res.http?.url.includes("https://api2.amplitude.com")) {
        return false;
      }
      /**
       * 20 Feb 24: Outlook scans emails for safe links and throws errors https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-865857552
       */
      if (
        typeof res.error?.message === "string" &&
        res.error.message.includes("Object Not Found Matching Id")
      ) {
        return false;
      }

      return true;
    },
  });
};

const configureRUM = (
  site: string,
  clientToken: string,
  applicationId: string,
  env?: string,
  version?: string,
) => {
  datadogRum.init({
    applicationId,
    clientToken,
    site,
    env,
    version,
    service: DD_SERVICE,
    defaultPrivacyLevel: DefaultPrivacyLevel.MASK_USER_INPUT,

    // sample rates
    telemetrySampleRate: 100,
    sessionSampleRate: 100,
    telemetryConfigurationSampleRate: 100,
    traceSampleRate: 100,
    sessionReplaySampleRate: 100,
    // end sample rates

    trackResources: true,
    trackUserInteractions: true,
    allowedTracingUrls: [
      // Match staging
      /https:\/\/.*\.frec\.com/,
      // Match prod
      "https://frec.com",
      // For local development
      /http:\/\/.*localhost:\d+/,
    ],
  });
  datadogRum.startSessionReplayRecording();
};
