import { MoneyMovementSourceType } from "../../generated/graphql";

export const TRANSFER_DEPOSIT_FROM_ACCOUNT_TYPES = [
  MoneyMovementSourceType.Ach,
  MoneyMovementSourceType.Treasury,
  MoneyMovementSourceType.FrecCash,
  MoneyMovementSourceType.LineOfCredit,
];

export const TRANSFER_DEPOSIT_TO_ACCOUNT_TYPES = [
  MoneyMovementSourceType.DirectIndex,
  MoneyMovementSourceType.Treasury,
  MoneyMovementSourceType.FrecCash,
];

export const MoneyMovementSourceTypeToText: Record<
  MoneyMovementSourceType,
  string
> = {
  [MoneyMovementSourceType.Ach]: "ACH",
  [MoneyMovementSourceType.Treasury]: "Treasury",
  [MoneyMovementSourceType.FrecCash]: "Cash",
  [MoneyMovementSourceType.DirectIndex]: "Direct Index",
  [MoneyMovementSourceType.LineOfCredit]: "Portfolio Line of Credit",
  [MoneyMovementSourceType.Wire]: "Wire",
  [MoneyMovementSourceType.Check]: "Check",
};

// Filters out specific account types for the "from" direction when a certain "to" account type is selected.
export const TRANSFER_DEPOSIT_FROM_ACCOUNT_TYPE_FILTER = (
  toType?: MoneyMovementSourceType,
) => {
  switch (toType) {
    // Disallow Line of Credit -> Frec Cash / Treasury transfers
    case MoneyMovementSourceType.FrecCash:
    case MoneyMovementSourceType.Treasury:
      return (fromType: MoneyMovementSourceType) =>
        fromType !== MoneyMovementSourceType.LineOfCredit;
    default:
      return () => true;
  }
};

// Filters out specific account types for "to" direction when a certain "from" account type is selected.
export const TRANSFER_DEPOSIT_TO_ACCOUNT_TYPE_FILTER = (
  fromType?: MoneyMovementSourceType,
) => {
  switch (fromType) {
    // Disallow Direct Index -> Direct Index and Line of Credit transfers
    case MoneyMovementSourceType.DirectIndex:
      return (toType: MoneyMovementSourceType) =>
        toType !== MoneyMovementSourceType.DirectIndex &&
        toType !== MoneyMovementSourceType.LineOfCredit;
    // Only allow Line of Credit -> DI transfers
    case MoneyMovementSourceType.LineOfCredit:
      return (toType: MoneyMovementSourceType) =>
        toType === MoneyMovementSourceType.DirectIndex;
    default:
      // Disable Line of Credit as a transfer destination
      return (toType: MoneyMovementSourceType) =>
        toType !== MoneyMovementSourceType.LineOfCredit;
  }
};
