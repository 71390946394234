// Minimum time (in seconds) that needs to elapse before an account update is allowed
export const MINIMUM_TIME_BEFORE_UPDATE_SECONDS = 1800;

// Dummy value for line of credit account id
export const LOAN_DEPOSIT_SOURCE = {
  ID: "41eb7798-ae1f-4941-938b-b5e1e4ce3636",
  ACCOUNT_NAME: "Loan Deposit Source",
};

// Dummy value for the direct index being bought with a line of credit
export const BORROWED_DIRECT_INDEX_ID = "borrowed-direct-index";
