import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from "react";

type ColorSchemeContextProps = {
  headerTheme: "light" | "dark";
  setHeaderTheme: (theme: "light" | "dark") => void;
};
const ColorSchemeContext = createContext<ColorSchemeContextProps>({
  headerTheme: "light",
  setHeaderTheme: () => undefined,
});

export const ColorSchemeContextProvider: FC<
  PropsWithChildren<PropsWithChildren>
> = ({ children }) => {
  const [headerTheme, setHeaderTheme] =
    useState<ColorSchemeContextProps["headerTheme"]>("light");

  return (
    <ColorSchemeContext.Provider
      value={{
        headerTheme,
        setHeaderTheme,
      }}
    >
      {children}
    </ColorSchemeContext.Provider>
  );
};

export const useColorSchemeContext = () => useContext(ColorSchemeContext);
