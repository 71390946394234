import { DateOnly } from "../date_utils";
import { PerformanceDataPeriod } from "../generated/graphql";

// This is used as the start date when querying in the "ALL" timeperiod mode
export const DI_START_DATE_ALL_TIME_PERIOD = new DateOnly("2023-01-01");

export const PERFORMANCE_PERIODS_USED = [
  PerformanceDataPeriod.M1,
  PerformanceDataPeriod.M3,
  PerformanceDataPeriod.M6,
  PerformanceDataPeriod.Y1,
  PerformanceDataPeriod.Ytd,
  PerformanceDataPeriod.All,
];

export const PerformanceDataPeriodToLabels = {
  [PerformanceDataPeriod.M1]: "1M",
  [PerformanceDataPeriod.M3]: "3M",
  [PerformanceDataPeriod.M6]: "6M",
  [PerformanceDataPeriod.Y1]: "1Y",
  [PerformanceDataPeriod.Y3]: "3Y",
  [PerformanceDataPeriod.Y5]: "5Y",
  [PerformanceDataPeriod.Y10]: "10Y",
  [PerformanceDataPeriod.Ytd]: "YTD",
  [PerformanceDataPeriod.All]: "ALL",
};

export const DirectIndexPerformanceDataPeriodToMonths = {
  [PerformanceDataPeriod.M1]: 1,
  [PerformanceDataPeriod.M3]: 3,
  [PerformanceDataPeriod.M6]: 6,
  [PerformanceDataPeriod.Y1]: 12,
  [PerformanceDataPeriod.Y3]: 36,
  [PerformanceDataPeriod.Y5]: 60,
  [PerformanceDataPeriod.Y10]: 120,
  [PerformanceDataPeriod.Ytd]: undefined,
  [PerformanceDataPeriod.All]: 0,
};

export const PerformanceDataPeriodToLabelText = {
  [PerformanceDataPeriod.M1]: "1 month",
  [PerformanceDataPeriod.M3]: "3 month",
  [PerformanceDataPeriod.M6]: "6 month",
  [PerformanceDataPeriod.Y1]: "1 year",
  [PerformanceDataPeriod.Y3]: "3 year",
  [PerformanceDataPeriod.Y5]: "5 year",
  [PerformanceDataPeriod.Y10]: "10 year",
  [PerformanceDataPeriod.Ytd]: "YTD",
  [PerformanceDataPeriod.All]: "all time",
};

/**
 * Calculates the start date to use for queries based on the given time periods
 */
export const startDateFromPeriod = (
  period: PerformanceDataPeriod,
): DateOnly => {
  if (period === PerformanceDataPeriod.All) {
    return DI_START_DATE_ALL_TIME_PERIOD;
  } else if (period === PerformanceDataPeriod.Ytd) {
    const currentYear = DateOnly.now("local").getFullYear();
    return new DateOnly(`${currentYear}-01-01`);
  } else {
    return DateOnly.now("local").addMonths(
      -(DirectIndexPerformanceDataPeriodToMonths[period] ?? 0),
    );
  }
};

/**
 * Calculates the sampling step to use based on the given period and date range
 */
export const getDateSamplingByPeriod = (
  period: PerformanceDataPeriod,
  startDate: DateOnly,
  endDate: DateOnly,
): number | undefined => {
  // No sampling for M1 and M3
  if (
    period === PerformanceDataPeriod.M1 ||
    period === PerformanceDataPeriod.M3
  ) {
    return undefined;
  }

  const monthsDelta = endDate.diff(startDate, "months");

  // No sampling for less than 6 months
  if (monthsDelta < 6) {
    return undefined;
  }

  if (period === PerformanceDataPeriod.M6) {
    return 3;
  }

  if (period === PerformanceDataPeriod.Ytd) {
    const monthsSinceStartOfYear = endDate.diff(
      new DateOnly(`${endDate.getFullYear()}-01-01`),
      "months",
    );
    if (monthsSinceStartOfYear <= 3) return undefined; // M3 sampling
    if (monthsSinceStartOfYear <= 6) return 3; // M6 sampling
  }

  if (monthsDelta < 12) {
    return 3;
  }

  return 7; // Default sampling for Y1 and longer periods
};
