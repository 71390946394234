import Decimal from "decimal.js";

import { BUSINESS_TIMEZONE, DateOnly } from "../date_utils";
import {
  MoneyMovementSourceType,
  ScheduledDepositExecution,
  ScheduledDepositExecutionStatus,
  ScheduledDepositPeriodType,
  ScheduledDepositType,
} from "../generated/graphql";

export enum ValidateScheduledDepositConfigValidationResult {
  AmountInvalid = "AmountInvalid",
  PrimaryAccountIdUndefined = "PrimaryAccountIdUndefined",
  PeriodicDepositBiWeeklySecondaryDayUndefined = "PeriodicDepositBiWeeklySecondaryDayUndefined",
  PeriodicDepositDayOfPeriodInvalid = "PeriodicDepositDayOfPeriodInvalid",
  PeriodicDepositDayOfPeriodUndefined = "PeriodicDepositDayOfPeriodUndefined",
  SourceDestinationInvalid = "SourceDestinationInvalid",
  SourceInvalid = "SourceInvalid",
  SourceSubAccountIdUndefined = "SourceSubAccountIdUndefined",
  TypeInvalid = "TypeInvalid",
}

export type ValidateScheduledDepositConfigArgs = {
  subAccountId: string; /// The specific sub account to deposit into, different direct indexes has different sub accounts
  primaryAccountId?: string; /// The external account to pull money from, if not frec cash
  sourceSubAccountId?: string; /// The subAccount to pull money from (in case of intra account transfer)
  type: ScheduledDepositType; /// Single or periodic
  sourceType: MoneyMovementSourceType; /// FrecCash or bank acc. enum MoneyMovementSourceType in GQL
  amount: Decimal;
  dayOfPeriod?: number; /// if type is PERIODIC and periodType is monthly, this is the day of the month
  secondaryDayOfPeriod?: number;
  /// Always default to the 1st of the month if periodType is quarterly
  periodType: ScheduledDepositPeriodType; /// monthly or quarterly. enum ScheduledDepositPeriodType in GQL
};

export const validateScheduledDepositConfig = (
  args: ValidateScheduledDepositConfigArgs,
): ValidateScheduledDepositConfigValidationResult[] => {
  const results = new Set<ValidateScheduledDepositConfigValidationResult>();

  if (args.type === ScheduledDepositType.SingleDeposit) {
    results.add(ValidateScheduledDepositConfigValidationResult.TypeInvalid);
  }

  // amount must be positive
  if (
    args.type === ScheduledDepositType.PeriodicDeposit &&
    args.amount.lte(0)
  ) {
    results.add(ValidateScheduledDepositConfigValidationResult.AmountInvalid);
  }

  if (
    args.type === ScheduledDepositType.PeriodicMarginInterest &&
    !args.amount.eq(0)
  ) {
    results.add(ValidateScheduledDepositConfigValidationResult.AmountInvalid);
  }

  // primaryAccountId must be set if sourceType is deposit account
  if (
    args.sourceType === MoneyMovementSourceType.Ach &&
    args.primaryAccountId === undefined
  ) {
    results.add(
      ValidateScheduledDepositConfigValidationResult.PrimaryAccountIdUndefined,
    );
  }

  // sourceSubAccountId must be set if sourceType is not DepositAccount
  if (
    (args.sourceType === MoneyMovementSourceType.DirectIndex ||
      args.sourceType === MoneyMovementSourceType.Treasury) &&
    args.sourceSubAccountId === undefined
  ) {
    results.add(
      ValidateScheduledDepositConfigValidationResult.SourceSubAccountIdUndefined,
    );
  }

  // dayOfPeriod must be set if type is periodic
  if (
    args.type === ScheduledDepositType.PeriodicDeposit &&
    args.dayOfPeriod === undefined
  ) {
    results.add(
      ValidateScheduledDepositConfigValidationResult.PeriodicDepositDayOfPeriodUndefined,
    );
  }

  // dayOfPeriod must be between 1 and 28, inclusive (makes things simpler for February)
  if (
    args.periodType === ScheduledDepositPeriodType.Monthly &&
    args.dayOfPeriod !== undefined &&
    (args.dayOfPeriod < 1 || args.dayOfPeriod > 28)
  ) {
    results.add(
      ValidateScheduledDepositConfigValidationResult.PeriodicDepositDayOfPeriodInvalid,
    );
  }

  // For weekly dayOfPeriod must be between 1-5 (representing Mon-Fri) inclusive
  if (
    args.periodType === ScheduledDepositPeriodType.Weekly &&
    args.dayOfPeriod !== undefined &&
    (args.dayOfPeriod < 1 || args.dayOfPeriod > 5)
  ) {
    results.add(
      ValidateScheduledDepositConfigValidationResult.PeriodicDepositDayOfPeriodInvalid,
    );
  }

  // For biweekly dayOfPeriod must be between 1-14 inclusive, secondaryDayOfPeriod must be between 15-28 inclusive
  if (
    args.periodType === ScheduledDepositPeriodType.BiWeekly &&
    args.dayOfPeriod !== undefined &&
    args.secondaryDayOfPeriod !== undefined &&
    (args.dayOfPeriod < 1 ||
      args.dayOfPeriod > 14 ||
      args.secondaryDayOfPeriod < 14 ||
      args.secondaryDayOfPeriod > 28)
  ) {
    results.add(
      ValidateScheduledDepositConfigValidationResult.PeriodicDepositDayOfPeriodInvalid,
    );
  }

  // dayOfPeriod must be 1 if periodType is quarterly
  if (
    args.periodType === ScheduledDepositPeriodType.Quarterly &&
    args.dayOfPeriod !== undefined &&
    args.dayOfPeriod !== 1
  ) {
    results.add(
      ValidateScheduledDepositConfigValidationResult.PeriodicDepositDayOfPeriodInvalid,
    );
  }

  if (
    args.periodType === ScheduledDepositPeriodType.BiWeekly &&
    !args.secondaryDayOfPeriod
  ) {
    results.add(
      ValidateScheduledDepositConfigValidationResult.PeriodicDepositBiWeeklySecondaryDayUndefined,
    );
  }

  // Both ids cannot be set (note: both can be unset for Wire, but that shouldn't be a recurring config)
  if (args.primaryAccountId && args.sourceSubAccountId) {
    results.add(ValidateScheduledDepositConfigValidationResult.SourceInvalid);
  }

  if (args.subAccountId === args.sourceSubAccountId) {
    results.add(
      ValidateScheduledDepositConfigValidationResult.SourceDestinationInvalid,
    );
  }

  return Array.from(results);
};

export enum ValidateScheduledDepositValidationResult {
  PrimaryAccountIdUndefined = "PrimaryAccountIdUndefined",
  AmountInvalid = "AmountInvalid",
  TypeInvalid = "TypeInvalid",
  SourceSubAccountIdUndefined = "SourceSubAccountIdUndefined",
  UndefinedSourceType = "UndefinedSourceType",
}

export type ScheduledDepositArgs = {
  type: ScheduledDepositType;
  amount: Decimal;
  sourceType?: MoneyMovementSourceType;
  primaryAccountId?: string;
  sourceSubAccountId?: string;
  periodType: ScheduledDepositPeriodType;
  dayOfPeriod?: number;
  secondaryDayOfPeriod?: number;
};

export const DEFAULT_DAY_OF_MONTH = 1;

export type GetNextDepositDateOnlyArgs = {
  referenceDate: DateOnly;
  periodType: ScheduledDepositPeriodType;
  dayOfPeriod: number;
  secondaryDayOfPeriod?: number;
  lastDepositDate?: DateOnly;
};

/**
 * To ensure we don't do repeated deposits on same day, we take into account
 * last deposit date to adjust the reference date.
 */
const getAdjustedReferenceDate = (args: {
  referenceDate: DateOnly;
  lastDepositDate?: DateOnly;
}): DateOnly => {
  const { lastDepositDate, referenceDate } = args;
  if (lastDepositDate) {
    if (lastDepositDate.lt(referenceDate)) {
      return referenceDate;
    } else if (lastDepositDate.gt(referenceDate)) {
      return lastDepositDate;
    } else {
      return referenceDate.addDays(1);
    }
  }

  return referenceDate;
};

/**
 * Given the period type, returns the day of the month of the next expected deposit
 */
export const _getAdjustedDayOfPeriod = (
  args: GetNextDepositDateOnlyArgs,
): number => {
  const { periodType, dayOfPeriod, secondaryDayOfPeriod } = args;

  const adjustedReferenceDate = getAdjustedReferenceDate({
    referenceDate: args.referenceDate,
    lastDepositDate: args.lastDepositDate,
  });

  // Weekly is represented by 1-5 denoting which day of the week starting from Mon-Fri
  if (periodType === ScheduledDepositPeriodType.Weekly) {
    const referenceDayOfWeek = adjustedReferenceDate.getDayOfWeek();
    if (referenceDayOfWeek === dayOfPeriod) {
      return adjustedReferenceDate.getDay();
    } else if (referenceDayOfWeek < dayOfPeriod) {
      return adjustedReferenceDate
        .addDays(dayOfPeriod - referenceDayOfWeek)
        .getDay();
    } else {
      // day of period is in past, so use next week
      return adjustedReferenceDate
        .addDays(7 - (referenceDayOfWeek - dayOfPeriod))
        .getDay();
    }
  } else if (periodType === ScheduledDepositPeriodType.BiWeekly) {
    if (
      secondaryDayOfPeriod &&
      adjustedReferenceDate.getDay() > dayOfPeriod &&
      adjustedReferenceDate.getDay() <= secondaryDayOfPeriod
    ) {
      // validation ensures secondaryDayOfPeriod should be present for BiWeekly
      return secondaryDayOfPeriod ?? dayOfPeriod;
    }
    return dayOfPeriod;
  } else if (periodType === ScheduledDepositPeriodType.Quarterly) {
    return DEFAULT_DAY_OF_MONTH;
  } else {
    return dayOfPeriod;
  }
};

/**
 * Computes the next deposit date based on the given reference date and an option last deposit date
 * Will return today's date or a date in the future, never a date in the past!
 */
export const getNextDepositDateOnly = (
  args: GetNextDepositDateOnlyArgs,
): DateOnly => {
  const { referenceDate, periodType, lastDepositDate } = args;
  // Only use the dayOfMonth if periodType is monthly, everything else use first day of month
  const adjustedDayOfMonth = _getAdjustedDayOfPeriod(args);
  // If a last deposit date was given
  if (lastDepositDate) {
    // The last deposit date might be from a long time ago, so see if the computed next deposit date is in the past
    const nextDepositDate = lastDepositDate
      .addDays(-lastDepositDate.getDay() + adjustedDayOfMonth)
      .addMonths(PERIOD_TYPE_TO_MONTHS[periodType]);
    // If the computed next deposit date is in the future or same as today, then use it
    if (!nextDepositDate.isBefore(referenceDate)) {
      return nextDepositDate;
    }
    // If the computed next deposit date is in the past, then use the same logic as if no last deposit date was given
  }
  // No last deposit date given (first time depositing)
  // Go to the next month if the dayOfMonth already passed
  if (referenceDate.getDay() > adjustedDayOfMonth) {
    return referenceDate
      .addDays(-referenceDate.getDay() + adjustedDayOfMonth)
      .addMonths(1);
  }
  // Use the same month, adjust day of month only
  return referenceDate.addDays(-referenceDate.getDay() + adjustedDayOfMonth);
};

export type GetNextDepositDateArgs = {
  startAt?: DateOnly;
  periodType: ScheduledDepositPeriodType;
  dayOfPeriod: number;
  secondaryDayOfPeriod?: number;
  lastDepositDate?: DateOnly;
};

/**
 * Given the periodType and dayOfMonth, computes the next deposit date based on today's date
 */
export const getNextDepositDate = (args: GetNextDepositDateArgs): DateOnly => {
  const {
    startAt,
    periodType,
    dayOfPeriod,
    lastDepositDate,
    secondaryDayOfPeriod,
  } = args;
  const today = DateOnly.now(BUSINESS_TIMEZONE);
  const referenceDate = startAt ? DateOnly.max(startAt, today) : today;
  return getNextDepositDateOnly({
    referenceDate,
    periodType,
    dayOfPeriod,
    secondaryDayOfPeriod,
    lastDepositDate,
  });
};

export const PERIOD_TYPE_TO_MONTHS = {
  [ScheduledDepositPeriodType.Weekly]: 0,
  [ScheduledDepositPeriodType.BiWeekly]: 0,
  [ScheduledDepositPeriodType.Monthly]: 1,
  [ScheduledDepositPeriodType.Quarterly]: 3,
};

export const PERIOD_TYPE_TO_DAYS = {
  [ScheduledDepositPeriodType.Weekly]: 7,
  [ScheduledDepositPeriodType.BiWeekly]: 14,
  [ScheduledDepositPeriodType.Monthly]: 30,
  [ScheduledDepositPeriodType.Quarterly]: 90,
};

/**
 * Checks if there is a previous successful direct index deposit within the cutoff date for the given periodType
 */
export const getPreviousSuccessfulScheduledDeposit = (
  periodType: ScheduledDepositPeriodType,
  executions: ScheduledDepositExecution[],
  cutoffDate: DateOnly,
): ScheduledDepositExecution | undefined => {
  return executions.find(
    (e) =>
      e.status === ScheduledDepositExecutionStatus.Completed &&
      // eventTime is recorded in UTC
      DateOnly.fromDateUTC(e.eventTime)
        .addMonths(PERIOD_TYPE_TO_MONTHS[periodType])
        .isAfter(cutoffDate),
  );
};
