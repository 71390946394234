export const HelpCenterArticleLinks = {
  "Who can open a Frec account?":
    "https://help.frec.com/hc/en-us/articles/10594982348564-Who-can-open-a-Frec-account-",
  "Is there a mobile app?":
    "https://help.frec.com/hc/en-us/articles/10594984672404-Is-there-a-mobile-app-",
  "Information required to open a Frec brokerage account":
    "https://help.frec.com/hc/en-us/articles/10595018689300-Information-required-to-open-a-Frec-brokerage-account",
  "Money Market funds":
    "https://help.frec.com/hc/en-us/articles/16260087962260-Money-market-funds",
  "Cash rebalance prompt":
    "https://help.frec.com/hc/en-us/articles/10561905317140-Cash-rebalance-prompt",
  "Pattern Day Trader (PDT)":
    "https://help.frec.com/hc/en-us/articles/10651498225172-Pattern-Day-Trader-PDT-",
  "Margin calls":
    "https://help.frec.com/hc/en-us/articles/10561820489492-Margin-calls",
  "Finding your account tax document(s)":
    "https://help.frec.com/hc/en-us/articles/16263802502804-Finding-your-account-tax-document-s-",
  "Margin call risk forecaster":
    "https://help.frec.com/hc/en-us/articles/10561922432788-Margin-call-risk-forecaster",
  "Securities we offer":
    "https://help.frec.com/hc/en-us/articles/10595020924052-Securities-we-offer",
  "What is Loan to Value (LTV)?":
    "https://help.frec.com/hc/en-us/articles/10561834375956-What-is-Loan-To-Value-LTV-",
  "Portfolio Concentration":
    "https://help.frec.com/hc/en-us/articles/10561851369492-Portfolio-Concentration",
  "How does Frec determine how much I can borrow?":
    "https://help.frec.com/hc/en-us/articles/10561847459860-How-does-Frec-determine-how-much-I-can-borrow-",
  "Margin interest":
    "https://help.frec.com/hc/en-us/articles/10561830259860-Margin-interest",
  "Trusted contact":
    "https://help.frec.com/hc/en-us/articles/16260682961428-Adding-or-updating-your-trusted-contact-information",
  "Market data":
    "https://help.frec.com/hc/en-us/articles/10651468606740-Market-Data",
  "How to select and transfer stock from an external brokerage account to Frec":
    "https://help.frec.com/hc/en-us/articles/10653344295444-How-to-select-and-transfer-stock-from-an-external-brokerage-account-to-Frec",
  "Regulatory trading fees":
    "https://help.frec.com/hc/en-us/articles/10651505001236-Regulatory-trading-fees",
  "Risks for an incoming ACATS transfer of an external portfolio line of credit":
    "https://help.frec.com/hc/en-us/articles/19626895015060-Risks-for-an-incoming-ACATS-transfer-of-an-external-portfolio-line-of-credit",
  "What type of assets can you transfer into Frec?":
    "https://help.frec.com/hc/en-us/articles/19984393231764-What-type-of-assets-can-you-transfer-into-Frec-",
  "What is a wash sale?":
    "https://help.frec.com/hc/en-us/articles/20152110381972-What-is-a-wash-sale-",
  "What type of tax forms will I get? Can I connect my turbotax account?":
    "https://help.frec.com/hc/en-us/articles/20216970898580-What-type-of-tax-forms-will-I-get-Can-I-connect-my-turbotax-account-",
  "How to read your 1099 tax form":
    "https://help.frec.com/hc/en-us/articles/16262941646740-How-to-read-your-1099-tax-form",
  "Filing Your Taxes: Turning Tax Losses Into Real Dollar Savings":
    "https://frec.com/resources/blog/how-to-turn-tax-losses-into-wins-a-playbook-for-tax-loss-harvesting-and-navigating-the-tax-filing-process",
  "White paper: Tax loss harvesting – ETFs vs. Direct Indexing":
    "https://frec.com/resources/blog/tax-loss-harvesting-etf-based-v-s-direct-indexing",
  "How do I transfer stocks into Frec Direct Indexing Portfolio?":
    "https://help.frec.com/hc/en-us/articles/20216850482452-How-do-I-transfer-stocks-into-Frec-s-Direct-Indexing-Portfolio",
  "Estimated tax impact":
    "https://help.frec.com/hc/en-us/articles/30636209258772-Estimated-tax-impact",
  "Tracking Preference":
    "https://help.frec.com/hc/en-us/articles/30943760422164-How-does-the-direct-index-tracking-preference-work",
  "The magic of borrowing against your stock portfolio":
    "https://frec.com/resources/blog/the-magic-of-borrowing-against-your-stock-portfolio",
} as const;
