import {
  formatUsd,
  MINIMUM_TRADE_VALUE,
  TradeValidationResult,
} from "@frec-js/common";

import { TradeFlowValidationResult } from "./hooks/useTradeValidation";

// Used to display different alert banner
export enum TradeValidationErrorBanner {
  CashRebalanceCallBuyNotAllowed = "CashRebalanceCallBuyNotAllowed",
  RegTBuyNotAllowed = "RegTBuyNotAllowed",
}

export const TradeValidationResultLookup: Record<
  TradeValidationResult,
  TradeFlowValidationResult
> = {
  SymbolTradingHalted: {
    errors: { symbol: "Trading is halted for this symbol" },
  },
  InsufficientCash: {
    errors: { quantity: "Insufficient cash" },
  },
  InsufficientQuantity: {
    errors: { quantity: "This exceeds the current holding" },
  },
  WillTriggerReBalance: {
    // Will likely revisit this in the future:
    // https://frectalk.slack.com/archives/C02M9HRUECS/p1655827196846369
    //
    //
    // warnings: { quantity: "Selling this much will trigger a re-balance" },
  },
  WillConcentratePosition: {
    // Will likely revisit this in the future:
    // https://www.figma.com/file/Hm6yPN4FloLBEH38ZVP99f?node-id=3754:7538#200948702
    //
    // warnings: {
    //   quantity:
    //     "This order will put you in a concentrated position, which affects how much you can borrow",
    // },
  },
  TradeValueTooLow: {
    errors: {
      quantity: `Minimum order value of ${formatUsd(MINIMUM_TRADE_VALUE)}`,
    },
  },
  FractionalBuyValueTooLow: {
    errors: {
      quantity: `Fractional share orders must have a value of at least ${formatUsd(
        MINIMUM_TRADE_VALUE,
      )}`,
    },
  },
  NoLimitPrice: {
    errors: { limit: "A limit price is required" },
  },
  InvalidSymbolPrice: {
    errors: { symbol: "Cannot find price" },
  },
  SymbolNotTradeable: {
    errors: { symbol: "Trading this symbol is not supported" },
  },
  LimitTooHigh: {
    errors: { limit: "The limit is too high" },
  },
  LimitTooLow: {
    errors: { limit: "The limit is too low" },
  },
  LimitInvalidPrecision: {
    errors: { limit: "Limit price can have up to two decimals" },
  },
  SymbolNotTradeableFractionally: {
    errors: {
      quantity:
        "Fractional trading is not supported for this symbol. Please enter a whole share value.",
    },
  },
  LotMatchingInvalidQuantity: {
    errors: {
      quantity: "Invalid quantity provided in lot matching instructions.",
    },
  },
  LotMatchingInvalidTaxLotEntry: {
    errors: {
      quantity: "Invalid tax lot entry provided in lot matching instructions.",
    },
  },
  LotMatchingInvalidSide: {
    errors: {
      quantity: "Lot selections cannot be made when placing a buy order.",
    },
  },
  InvalidQuantity: {
    errors: { quantity: "Invalid quantity provided." },
  },
  InvalidQuantityPrecision: {
    errors: {
      quantity:
        "Shares can be up to 5 decimal and amount can be up to 2 decimal.",
    },
  },
  MaxSellQtyAllowedForNotionalSellOnly: {
    // Since we don't have max sell qty on UI, not possible to get this error
  },
  LimitPriceRequiredForFractionalBuyQueued: {
    errors: {
      quantity:
        "Limit price is required when placing fractional buy orders after hours.",
    },
  },
  LimitPriceRequiredForNotionalSellQueued: {
    errors: {
      quantity:
        "Limit price is required for placing sell orders in dollar amounts after hours.",
    },
  },
  MarketOpenInvalidQueue: {
    errors: {
      quantity: "Queued orders cannot be made while the market is open.",
    },
  },
  MarketClosed: {
    errors: { quantity: "The market is currently closed" },
  },
  PatternDayTrade: {
    // Removed this in favor of warning on trade review screen
    // warnings: {
    //   quantity: `Trade exceeds permitted number of day trades in these 5 business days for accounts with less than ${formatUsd(
    //     PATTERN_DAY_TRADE_EQUITY_REQ
    //   )} in equity.`,
    // },
  },
  BuyNotAllowed: {
    errors: { quantity: "You are not allowed to make buy orders" },
  },
  SellNotAllowed: {
    errors: { quantity: "You are not allowed to make sell orders" },
  },
  NotionalSellNotAllowed: {
    errors: {
      quantity:
        "You cannot sell a dollar amount of this symbol because it also exists in your direct index portfolio.",
    },
  },
  UserSymbolNotAllowed: {
    errors: { quantity: "You are not allowed to trade this security" },
  },
  RebalanceCallBuyNotAllowed: {
    errors: {
      banner: TradeValidationErrorBanner.CashRebalanceCallBuyNotAllowed,
    },
  },
  RegTBuyNotAllowed: {
    errors: {
      banner: TradeValidationErrorBanner.RegTBuyNotAllowed,
    },
  },
  MMFQuantityFractionalNotAllowed: {
    errors: {
      quantity:
        "Fractional trading is not supported for this symbol. Please enter a whole share value.",
    },
  },
  MMFDollarFractionalNotAllowed: {
    errors: {
      quantity:
        "This symbol can only be purchased in whole dollars. Please enter a whole dollar value.",
    },
  },
  MMFInvestmentTooLow: {
    errors: {
      quantity: "The order does not meet the minimum investment amount.",
    },
  },
  MMFLimitNotAllowed: {
    errors: { limit: "Limit order not allowed." },
  },
  MMFBuyNotAllowed: {
    errors: {
      quantity:
        "Buy orders for this symbol are not supported for this account type.",
    },
  },
  PennyStockBuyNotAllowed: {
    errors: { symbol: "Buy orders for symbols below $1 are not supported." },
  },
};
