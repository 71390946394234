import { FC } from "react";

import { windowIfAvailable } from "../utils/windowIfAvailable";

type Props = {
  refresh?: () => void;
  title?: string;
};

export const Error: FC<Props> = ({ refresh, title = "500" }: Props) => {
  return (
    <div className="flex min-h-full w-full flex-col">
      <div className="mx-auto mt-6 w-full max-w-7xl grow px-6 pb-8 md:mt-14 lg:px-12">
        <p className="text-frecDarkGray mb-2 font-normal">{title}</p>
        <h1 className="mb-2">Something went wrong</h1>
        <p className="text-frecDarkGray mb-6 max-w-[426px]">
          Sorry, we’re experiencing an internal server issue. Try refreshing the
          page or come back later.
        </p>
        <button
          className="mantine-UnstyledButton-root text-frecBlack no-underline decoration-2 underline-offset-4 hover:underline disabled:cursor-not-allowed disabled:!no-underline disabled:opacity-40"
          type="button"
          onClick={() => refresh?.() ?? windowIfAvailable?.location.reload()}
        >
          <div className="flex">Refresh page</div>
        </button>
      </div>
    </div>
  );
};

export default Error;
