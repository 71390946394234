import * as stats from "simple-statistics";

import { sampleNormalN } from "../utils/stats";
import { SimulatorError, SimulatorErrors } from "./model";

/**
 * A method to resample a set of deltas to a new target return. The logic for using this
 * in practices is as follows:
 *
 * 1. Calculate the mean and standard deviation of your ground truth deltas (i.e. SPY returns)
 *
 * 2. Sample a new set of deltas from a normal distribution with the same mean and standard deviation
 *    as your ground truth deltas `deltaPrime`
 *
 * 3. Select a new desired return for your new deltas `Y`. This could either be
 *   - sampled from the ground truth returns to generate a "random" return
 *   - provided by a user to assume a given return
 *
 * 4. Solve for `c` such that the cumulative product of `deltaPrime[i] + c` is `Y`. Note that instead of
 *    solving for `c` directly, we observe that `Y` moves monotonically with `c` and we can use a binary search
 *    to approximate `c`
 *
 *
 * This method is preferred to simply sampling from a normal distribution because it
 * seeks to retain the dependant probability structure of the original deltas. Market returns,
 * as they are tied to the GDP are not independent events. The odds of having a positive return
 * after a period of negative returns is higher than the odds of having continued
 * negative returns.
 *
 */
export const resampleDeltas = (
  deltas: number[],
  n: number,
  totalReturn: number,
): number[] => {
  const { mean, sd } = moments(deltas);
  const sampledDeltas = sampleNormalN(mean, sd, n);

  if (totalReturn <= 0) {
    throw new Error("totalReturn must be greater than 0");
  }

  return scaleDeltas(sampledDeltas, totalReturn);
};

// Used to scale a DatePoint[] or DatePairPoint[]
export const scaleTimeSeries = <T extends { value: number }>(
  timeSeries: T[],
  totalReturn: number,
): T[] => {
  if (totalReturn <= 0) {
    throw new SimulatorError(
      SimulatorErrors.InvalidInput,
      "targetFinalReturn must be greater than 0",
    );
  }
  const scaled = scaleDeltas(
    timeSeries.map((value) => value.value),
    totalReturn,
  );
  return timeSeries.map((t, index) => ({
    ...t,
    value: scaled[index],
  }));
};

/**
 * Scale a set of deltas so that the cumulative product of the deltas is equal to the
 * `totalReturn` passed in.
 *
 * Uses a binary search to find the value of `c` such that we minimize the difference
 * between total return and the cumulative product of `1 + delta[i] + c`
 *
 * @totalReturn: the total return we want to achieve over the entire period. E.g. a 7% per year
 *               return over 30 years is 1.07^30 = 7.61225504
 */
export const scaleDeltas = (
  deltas: number[],
  totalReturn: number,
): number[] => {
  const f = (c: number) => {
    const _totalReturn = deltas.reduce((acc, value) => {
      return acc * (1 + value + c);
    }, 1);

    return _totalReturn - totalReturn;
  };

  if (totalReturn <= 0) {
    throw new SimulatorError(
      SimulatorErrors.InvalidInput,
      "totalReturn must be greater than 0",
    );
  }

  /**
   * Choose a +/- bound for the binary search. We need a bound for the value of `c`. We know that
   * d = target^(1/n), would work for a d (d^n = target). We can use this as the average value that
   * makes sense for `1 + delta[i] + c`, that is: target^(1/n) ~= 1 + delta[i] + c
   *   => c ~= target^(1/n) - 1 - delta[i]
   *   => approx_c = target^(1/n) - 1 - mean(delta)
   *
   * Next we need a +/- for the bound, let's just use +/- (Math.abs(approxC) * 2 + 1), a bound that
   * I arrived at by testing a lot of randomly generated vectors and target returns.
   */
  const approxC = totalReturn ** (1 / deltas.length) - 1 - stats.mean(deltas);

  const c = stats.bisect(
    f,
    approxC - (Math.abs(approxC) * 2 + 1),
    approxC + (Math.abs(approxC) * 2 + 1),
    10000, // max iterations
    0.0000001, // error threshold, set though trial and error
  );

  return deltas.map((value) => value + c);
};

const moments = (list: number[]) => ({
  mean: stats.mean(list),
  sd: stats.standardDeviation(list),
});
