export const RELATIONSHIP_OPTIONS = [
  "Spouse",
  "Parent",
  "Child",
  "Sibling",
  "Other family member",
  "Friend",
  "Legal guardian",
  "Power of attorney",
  "Executor of will",
  "Financial advisor",
  "Attorney",
  "Accountant",
  "Healthcare proxy",
  "Business partner",
  "Other: specify",
];

export const OTHER_RELATIONSHIP = RELATIONSHIP_OPTIONS[
  RELATIONSHIP_OPTIONS.length - 1
] as string;
