import Decimal from "decimal.js";

import { DateOnly } from "../date_utils";
import { makeNumber } from "./number";

export enum StockOptionType {
  CALL = "CALL",
  PUT = "PUT",
}

export interface StockOption {
  underlyingSymbol: string;
  strike: Decimal;
  expiry: DateOnly;
  optionType: StockOptionType;
}

export const isValidOptionsSymbol = (optionSymbol: string): boolean => {
  try {
    getStockOption(optionSymbol);
    return true;
  } catch (e) {
    return false;
  }
};

// inverse of getOptionsSymbol
export const getStockOption = (optionSymbol: string): StockOption => {
  // underlying symbol is the first characters after "O:" (optionally) until the first number
  const regex = optionSymbol.match(
    /(O:)?([A-Z]+)(\d{2})(\d{2})(\d{2})([PC])(\d{8})/,
  );

  if (
    !regex ||
    regex.length != 8 ||
    regex.slice(2).some((x) => x == undefined)
  ) {
    throw new Error("Invalid option symbol");
  }

  const underlyingSymbol = regex[2] as string;

  const year = ("20" + regex[3]) as string;
  const month = regex[4] as string;
  const day = regex[5] as string;
  const expiry = new DateOnly(`${year}-${month}-${day}`);
  const optionTypeChar = regex[6];
  let optionType: StockOptionType;
  if (optionTypeChar == "C") {
    optionType = StockOptionType.CALL;
  } else {
    optionType = StockOptionType.PUT;
  }
  const strike = new Decimal(regex[7] as string).div(1000);
  return {
    underlyingSymbol,
    strike,
    expiry,
    optionType,
  };
};

/**
 * Get the options symbol for a given option, see: https://help.yahoo.com/kb/SLN13884.html
 * example:
 * getOptionsSymbol({ underlyingSymbol: "AAPL", strike: 15, expiry: new DateOnly("2023-01-22"), optionType: StockOptionType.CALL })
 * // returns "AAPL230122C00015000"
 */
export const getOptionsSymbol = (o: StockOption): string => {
  const priceString = (makeNumber(o.strike) * 1000).toFixed(0).padStart(8, "0");
  o.expiry.getDay;
  const dateString =
    (o.expiry.getFullYear() - 2000).toString() +
    (o.expiry.getMonthNumber() + 1).toString().padStart(2, "0") +
    o.expiry.getDay().toString().padStart(2, "0");

  let typeChar = "P";
  if (o.optionType === StockOptionType.CALL) {
    typeChar = "C";
  }

  const optionSymbol = o.underlyingSymbol + dateString + typeChar + priceString;

  return optionSymbol;
};
