import {
  BusinessEntityIndustry,
  BusinessEntityInvestmentObjective,
  BusinessEntityInvestmentPlan,
  BusinessEntityWithdrawFrequency,
  EmploymentStatus,
  InvestmentExperience,
  InvestmentObjective,
  InvestmentProfileRangeInput,
  LiquidityNeeds,
  RiskTolerance,
  TimeHorizon,
  UserInvestmentProfileInput,
} from "../generated/graphql";
import { unsafelyCastDefined } from "./typeUtils";

export const EMPLOYMENT_STATUS_OPTIONS: { label: string; value: string }[] = [
  { label: "Employed", value: EmploymentStatus.Employed },
  { label: "Retired", value: EmploymentStatus.Retired },
  { label: "Student", value: EmploymentStatus.Student },
  { label: "Unemployed", value: EmploymentStatus.Unemployed },
];

export const NET_WORTH_OPTION_MAP: Record<string, InvestmentProfileRangeInput> =
  {
    "< $250k": { min: 100001, max: 200000 },
    "$250k - $1m": { min: 500001, max: 1000000 },
    "$1m - $5m": { min: 1000001, max: 5000000 },
    "$5m+": { min: 5000001, max: 9999999 },
  };
export const NET_WORTH_OPTIONS = Object.entries(NET_WORTH_OPTION_MAP).map(
  ([label, range], index) => ({ label, value: `${index}`, range }),
);
export const getSelectedNetWorthValue = (min?: number, max?: number) =>
  NET_WORTH_OPTIONS.findIndex(
    (o) => o.range.min <= (min ?? 0) && o.range.max >= (max ?? 0),
  ).toString();

export const ANNUAL_INCOME_OPTIONS_MAP: Record<
  string,
  InvestmentProfileRangeInput
> = {
  "< $300k": { min: 200001, max: 300000 },
  "$300k - $500k": { min: 300001, max: 500000 },
  "$500k - $1.2m": { min: 500001, max: 1200000 },
  "$1.2m+": { min: 1200001, max: 9999999 },
};
export const ANNUAL_INCOME_OPTIONS = Object.entries(
  ANNUAL_INCOME_OPTIONS_MAP,
).map(([label, range], index) => ({ label, value: `${index}`, range }));
export const getSelectedIncomeValue = (min?: number, max?: number) =>
  ANNUAL_INCOME_OPTIONS.findIndex(
    (o) => o.range.min <= (min ?? 0) && o.range.max >= (max ?? 0),
  ).toString();

export const EXPERIENCE_OPTIONS = [
  { label: "None", value: InvestmentExperience.None },
  { label: "Not much", value: InvestmentExperience.Limited },
  { label: "Intermediate", value: InvestmentExperience.Good },
  { label: "Expert", value: InvestmentExperience.Extensive },
];

export const RISK_TOLERANCE = [
  { label: "Low", value: RiskTolerance.Low },
  { label: "Medium", value: RiskTolerance.Medium },
  { label: "High", value: RiskTolerance.High },
];

export const RISK_TOLERANCE_TO_OBJECTIVE_MAPPING = {
  [RiskTolerance.Low]: InvestmentObjective.GrowthIncome,
  [RiskTolerance.Medium]: InvestmentObjective.Balanced,
  [RiskTolerance.High]: InvestmentObjective.MaximumGrowth,
  [RiskTolerance.Unspecified]: InvestmentObjective.Unspecified,
};

export const TIME_HORIZON = [
  { label: "< 3 years", value: TimeHorizon.Short },
  { label: "3 - 10 years", value: TimeHorizon.Average },
  { label: "10+ years", value: TimeHorizon.Longest },
];

export const LIQUIDITY_NEEDS = [
  { label: "Not Important", value: LiquidityNeeds.NotImportant },
  { label: "Somewhat Important", value: LiquidityNeeds.SomewhatImportant },
  { label: "Very Important", value: LiquidityNeeds.VeryImportant },
];

export const BUSINESS_INVESTMENT_OBJECTIVE = [
  {
    label: "Capital Preservation",
    value: BusinessEntityInvestmentObjective.CapitalPreservation,
  },
  { label: "Income", value: BusinessEntityInvestmentObjective.Income },
  { label: "Growth", value: BusinessEntityInvestmentObjective.Growth },
  {
    label: "Speculation",
    value: BusinessEntityInvestmentObjective.Speculation,
  },
];

export const BUSINESS_INVESTMENT_PLAN = [
  {
    label: "Active trading",
    value: BusinessEntityInvestmentPlan.ActiveTrading,
  },
  {
    label: "Long term investing",
    value: BusinessEntityInvestmentPlan.LongTermInvesting,
  },
  {
    label: "Short term investing",
    value: BusinessEntityInvestmentPlan.ShortTermInvesting,
  },
];

export const BUSINESS_WITHDRAW_FREQUENCY = [
  { label: "Rare", value: BusinessEntityWithdrawFrequency.Rare },
  { label: "Occasional", value: BusinessEntityWithdrawFrequency.Occasional },
  { label: "Frequent", value: BusinessEntityWithdrawFrequency.Frequent },
];

export const BUSINESS_INDUSTRY = [
  {
    label: "Agriculture, forestry, or fishing",
    value: BusinessEntityIndustry.AgricultureForestryFishing,
  },
  { label: "Construction", value: BusinessEntityIndustry.Construction },
  {
    label: "Finance, insurance, or real estate",
    value: BusinessEntityIndustry.FinanceInsuranceRealEstate,
  },
  { label: "Manufacturing", value: BusinessEntityIndustry.Manufacturing },
  { label: "Mining", value: BusinessEntityIndustry.Mining },
  {
    label: "Public administration",
    value: BusinessEntityIndustry.PublicAdministration,
  },
  { label: "Retail trade", value: BusinessEntityIndustry.RetailTrade },
  { label: "Services", value: BusinessEntityIndustry.Services },
  {
    label:
      "Transportation, communications, electric, gas, or sanitary services",
    value:
      BusinessEntityIndustry.TransportationCommunicationsElectricGasSanitaryServices,
  },
  { label: "Wholesale trade", value: BusinessEntityIndustry.WholesaleTrade },
];

export const derivedInvestmentProfileInput = (args: {
  income: string;
  netWorth: string;
  liquidityNeeds: LiquidityNeeds;
  liquidityTimeHorizon: TimeHorizon;
  investmentExperience: InvestmentExperience;
  riskTolerance: RiskTolerance;
}) => {
  const data: UserInvestmentProfileInput = {
    netWorthMin: 0,
    netWorthMax: 0,
    annualIncomeMin: 0,
    annualIncomeMax: 0,
    investmentObjective: InvestmentObjective.Unspecified,
    liquidityNeeds:
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      args.liquidityNeeds ?? LiquidityNeeds.Unspecified,
    liquidityTimeHorizon:
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      args.liquidityTimeHorizon ?? TimeHorizon.Unspecified,
    investmentExperience: args.investmentExperience,
    riskTolerance: args.riskTolerance,
  };

  if (args.netWorth) {
    const { min, max } = unsafelyCastDefined(
      NET_WORTH_OPTIONS[+args.netWorth],
    ).range;
    data.netWorthMin = min;
    data.netWorthMax = max;
  }

  if (args.income) {
    const { min, max } = unsafelyCastDefined(
      ANNUAL_INCOME_OPTIONS[+args.income],
    ).range;
    data.annualIncomeMax = max;
    data.annualIncomeMin = min;
  }

  // Investment objective is derived from the riskTolerance field
  if (
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    data.riskTolerance
  ) {
    data.investmentObjective =
      RISK_TOLERANCE_TO_OBJECTIVE_MAPPING[data.riskTolerance];
  }

  return data;
};
