import { DateTime } from "luxon";

import { ValidationResultLevel } from "../common/enums";
import { MINIMUM_TIME_BEFORE_UPDATE_SECONDS } from "../constants/accountConstants";

export type UpdateClearingHouseAccountArgs = {
  clearingAccountCreationTime: Date;
  isEditAllowed: boolean;
};

export enum AccountUpdateValidationResult {
  InsufficientTimeElapsedSinceCreation = "InsufficientTimeElapsedSinceCreation",
  NotAllowed = "NotAllowed",
}

export const AccountUpdateValidationResultLevelMap: Record<
  AccountUpdateValidationResult,
  ValidationResultLevel
> = {
  InsufficientTimeElapsedSinceCreation: ValidationResultLevel.Error,
  NotAllowed: ValidationResultLevel.Error,
};

export const validateAccountUpdate = (args: UpdateClearingHouseAccountArgs) => {
  if (!args.isEditAllowed) return AccountUpdateValidationResult.NotAllowed;

  const diff = DateTime.now().diff(
    DateTime.fromJSDate(args.clearingAccountCreationTime),
  );
  if (diff.milliseconds / 1000 < MINIMUM_TIME_BEFORE_UPDATE_SECONDS) {
    return AccountUpdateValidationResult.InsufficientTimeElapsedSinceCreation;
  }
};
