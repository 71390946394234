import Decimal from "decimal.js";
import { DateTime } from "luxon";

import { BUSINESS_TIMEZONE, DateOnly } from "../date_utils";
import {
  ActionContext,
  CashTransfer,
  CashTransferDirection,
  CashTransferMethod,
  CashTransferStatus,
  LiveCashHolding,
  LiveCashHoldingType,
  MoneyMovementSourceType,
  MoneyMovementSourceTypeGroup,
  PortfolioAggregateValues,
} from "../generated/graphql";
import { ZERO } from "../utils";

/**
 * frecCash = loan - fees + otherCash
 */
export function getFrecCash(cashHoldings: LiveCashHolding[]) {
  const cashBalance = getCashBalance(cashHoldings);
  const loanBalance = getLoanBalance(cashHoldings);
  const feeBalance = getFeeBalance(cashHoldings);

  return loanBalance
    .minus(feeBalance)
    .plus(cashBalance)
    .toDecimalPlaces(2, Decimal.ROUND_DOWN);
}

/**
 * AKA "otherCash" -- Net cash from trades, ACH transfers, and cash ACATS.
 */
export function getCashBalance(cashHoldings: LiveCashHolding[]) {
  return cashHoldings
    .filter((h) => h.type == LiveCashHoldingType.CashBalance)
    .reduce((prev, cur) => prev.add(cur.value), new Decimal(0));
}

export function getLoanBalance(cashHoldings: LiveCashHolding[]) {
  return cashHoldings
    .filter((h) => h.type == LiveCashHoldingType.Loan)
    .reduce((prev, cur) => prev.add(cur.value), new Decimal(0));
}

export function getFeeBalance(cashHoldings: LiveCashHolding[]) {
  return cashHoldings
    .filter((h) => h.type == LiveCashHoldingType.Fee)
    .reduce((prev, cur) => prev.add(cur.value), new Decimal(0));
}

export type AchTransferAmountByDirection = {
  depositAmount: Decimal;
  withdrawalAmount: Decimal;
};

/**
 * Based off: https://developer.apexclearing.com/apexclearing/docs/processing-windows
 * ACH Deposits have cut off time of 3 PM CT (withdrawals have 2:30 PM CT)
 * If not passed, we just take the lower cut off time (2:30 PM CT)
 */
function _getAchCutOffTimeForDate(
  dateToCheck: DateOnly,
  direction?: CashTransferDirection,
): Date {
  return direction === CashTransferDirection.Deposit
    ? dateToCheck.toDateSpecificTime(BUSINESS_TIMEZONE, 16, 0)
    : dateToCheck.toDateSpecificTime(BUSINESS_TIMEZONE, 15, 30);
}

/**
 * Friday Morning (should account for Thursday postponed)
 * Friday Evening (should not count same day, as it will get postponed)
 * Saturday all day (Should account for Friday Postponed)
 * Sunday all day (Should account for Friday Postponed)
 * Monday Morning (Should account for Friday Postponed)
 * Monday evening (Should not count same day)
 */
export function getAchCutOffTime(direction?: CashTransferDirection): Date {
  const today = DateOnly.now(BUSINESS_TIMEZONE);
  const todayCutOffTime = _getAchCutOffTimeForDate(today, direction);
  const currentTime = DateTime.now().setZone(BUSINESS_TIMEZONE);
  const isPastAchCutOffTime =
    today.isBusinessDay() && currentTime.valueOf() >= todayCutOffTime.valueOf();
  return isPastAchCutOffTime
    ? todayCutOffTime
    : // We use previous business day to make sure we get all the postponed events as well
      _getAchCutOffTimeForDate(today.prevBusinessDay(), direction);
}

export function getCurrentDayAchTransferAmount(
  currentDayAchTransfers: Pick<
    CashTransfer,
    "amount" | "direction" | "status"
  >[],
): AchTransferAmountByDirection {
  const initialReduceValue: AchTransferAmountByDirection = {
    depositAmount: ZERO,
    withdrawalAmount: ZERO,
  };

  return currentDayAchTransfers.reduce((acc, transfer) => {
    if (transfer.status === CashTransferStatus.Canceled) {
      return acc;
    } else if (transfer.direction === CashTransferDirection.Deposit) {
      return {
        ...acc,
        depositAmount: acc.depositAmount.plus(transfer.amount),
      };
    } else {
      return {
        ...acc,
        withdrawalAmount: acc.withdrawalAmount.plus(transfer.amount),
      };
    }
  }, initialReduceValue);
}

export function getAvailableToWithdrawFromSource(
  fromType: MoneyMovementSourceType,
  aggregate: PortfolioAggregateValues,
): Decimal | undefined {
  switch (fromType) {
    case MoneyMovementSourceType.FrecCash:
      // This includes loan value
      return aggregate.cashValue ?? ZERO;
    case MoneyMovementSourceType.DirectIndex:
      return aggregate.securityValue.add(aggregate.cashValue) ?? ZERO;
    case MoneyMovementSourceType.Treasury:
      return aggregate.mmfHoldingValue.add(aggregate.cashValue) ?? ZERO;
    default:
      return undefined;
  }
}

export function getCashTransferMethodFromSource(
  fromType: MoneyMovementSourceType,
  toType: MoneyMovementSourceType,
): CashTransferMethod | undefined {
  if (
    fromType === MoneyMovementSourceType.Ach ||
    toType === MoneyMovementSourceType.Ach
  ) {
    return CashTransferMethod.Ach;
  } else if (
    fromType === MoneyMovementSourceType.Wire ||
    toType === MoneyMovementSourceType.Wire
  ) {
    return CashTransferMethod.Wire;
  } else if (toType === MoneyMovementSourceType.Check) {
    return CashTransferMethod.Check;
  } else {
    return undefined;
  }
}

export function getCashTransferDirectionFromSource(
  fromType: MoneyMovementSourceType,
  toType: MoneyMovementSourceType,
): CashTransferDirection | undefined {
  if (
    fromType === MoneyMovementSourceType.Ach ||
    fromType === MoneyMovementSourceType.Wire
  ) {
    return CashTransferDirection.Deposit;
  } else if (
    toType === MoneyMovementSourceType.Ach ||
    toType === MoneyMovementSourceType.Wire ||
    toType === MoneyMovementSourceType.Check
  ) {
    return CashTransferDirection.Withdrawal;
  } else {
    return undefined;
  }
}

export const CASH_TRANSFER_ACTION_CONTEXT_MAP: Record<
  CashTransferDirection,
  Record<CashTransferMethod, ActionContext>
> = {
  DEPOSIT: {
    ACH: ActionContext.AchIn,
    WIRE: ActionContext.WireIn,
    CHECK: ActionContext.None,
  },
  WITHDRAWAL: {
    ACH: ActionContext.AchOut,
    WIRE: ActionContext.WireOut,
    CHECK: ActionContext.None,
  },
};

export const MONEY_MOVEMENT_SOURCE_GROUP_MAP: Record<
  MoneyMovementSourceType,
  MoneyMovementSourceTypeGroup
> = {
  FREC_CASH: MoneyMovementSourceTypeGroup.SubAccount,
  DIRECT_INDEX: MoneyMovementSourceTypeGroup.SubAccount,
  TREASURY: MoneyMovementSourceTypeGroup.SubAccount,
  ACH: MoneyMovementSourceTypeGroup.DepositAccount,
  WIRE: MoneyMovementSourceTypeGroup.DepositAccount,
  CHECK: MoneyMovementSourceTypeGroup.DepositAccount,
  LINE_OF_CREDIT: MoneyMovementSourceTypeGroup.LineOfCredit,
};
