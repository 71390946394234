import { DateOnly } from "@frec-js/common";

import {
  AddressType,
  AuthUserFragment,
  EmailType,
  EmploymentStatus,
  GenericAddress,
  InvestmentExperience,
  InvestmentObjective,
  LiquidityNeeds,
  PhoneNumberType,
  RiskTolerance,
  TimeHorizon,
  UserContactEmail,
  UserContactPhone,
  UserDisclosures,
  UserEmploymentDetails,
  UserIdentityDetails,
  UserInvestmentProfile,
  UserPersonalDetails,
  UserTrustedContact,
} from "../../generated/graphql";

export const EMPTY_BUSINESS_ADDRESS: GenericAddress = {
  id: "",
  addressLine1: "",
  addressLine2: "",
  addressType: AddressType.Business,
  city: "",
  state: "",
  zipCode: "",
  country: "USA",
};

export const EMPTY_TRUSTEE = {
  firstName: "",
  lastName: "",
  dateOfBirth: "",
  ssn: "",
  idType: "",
  idNumber: "",
  idIssuedBy: "",
  idExpirationDate: "",
  address: {
    addressLine1: "",
    addressLine2: "",
    addressType: AddressType.Default,
    city: "",
    state: "",
    zipCode: "",
    country: "USA",
  },
  disclosure: {
    affiliatedExchangeOrFINRA: false,
    controlPerson: false,
    politicallyExposed: false,
    politicalTitle: "",
    politicalOrganization: "",
    immediateFamily: "",
    isNone: true,
    companySymbols: [],
  },
};

export const EMPTY_BUSINESS_INDIVIDUAL = {
  firstName: "",
  lastName: "",
  title: "",
  ssn: "",
  birthDay: "",
  birthMonth: "",
  birthYear: "",
  address: {
    addressLine1: "",
    addressLine2: "",
    addressType: AddressType.Default,
    city: "",
    state: "",
    zipCode: "",
    country: "USA",
  },
  disclosure: {
    affiliatedExchangeOrFINRA: false,
    controlPerson: false,
    politicallyExposed: false,
    politicalTitle: "",
    politicalOrganization: "",
    immediateFamily: "",
    isNone: true,
    companySymbols: [],
  },
};

export const EMPTY_USER_ADDRESS: GenericAddress = {
  id: "",
  addressLine1: "",
  addressLine2: "",
  addressType: AddressType.Residential,
  city: "",
  state: "",
  zipCode: "",
  country: "USA",
};

export const EMPTY_USER_EMPLOYMENT_DETAILS: UserEmploymentDetails = {
  id: "",
  employerName: "",
  employmentStatus: EmploymentStatus.Unspecified,
  occupation: "",
  yearsEmployed: 0,
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  zipCode: "",
  country: "USA",
};

export const EMPTY_USER_TRUSTED_CONTACT: UserTrustedContact = {
  id: "",
  relationship: "",
  firstName: "",
  lastName: "",
  middleName: undefined,
  email: "",
  phoneNumber: "",
  phoneNumberType: PhoneNumberType.Mobile,
  addressLine1: undefined,
  addressLine2: undefined,
  city: undefined,
  state: undefined,
  zipCode: undefined,
  country: "USA",
};

export const EMPTY_USER_IDENTITY: UserIdentityDetails = {
  id: "",
  citizenshipCountry: "",
  dateOfBirth: new DateOnly("1800-01-01"),
  ssn: "",
  visaExpiration: new DateOnly("2030-01-01"),
  maritalStatus: undefined,
  numberOfDependents: 0,
};

export const EMPTY_USER_INVESTMENT_PROFILE: UserInvestmentProfile = {
  id: "",
  annualIncomeMax: 0,
  annualIncomeMin: 0,
  investmentExperience: InvestmentExperience.Unspecified,
  investmentObjective: InvestmentObjective.Unspecified,
  liquidityNeeds: LiquidityNeeds.Unspecified,
  liquidityTimeHorizon: TimeHorizon.Unspecified,
  netWorthMax: 0,
  netWorthMin: 0,
  riskTolerance: RiskTolerance.Unspecified,
};

export const EMPTY_USER_DISCLOSURES: UserDisclosures = {
  id: "",
  affiliatedExchangeOrFINRA: false,
  companySymbols: "",
  controlPerson: false,
  firmName: "",
  immediateFamily: "",
  politicalOrganization: "",
  politicallyExposed: false,
  backupWithholding: false,
};

export const EMPTY_USER_PERSONAL_DETAILS: UserPersonalDetails = {
  id: "",
  firstName: "",
  middleName: "",
  lastName: "",
  fullName: "",
};

export const EMPTY_USER_CONTACT_EMAIL: UserContactEmail = {
  id: "",
  email: "",
  emailType: EmailType.Primary,
  verified: false,
};

export const EMPTY_USER_CONTACT_PHONE: UserContactPhone = {
  id: "",
  phoneNumber: "",
  phoneNumberVerified: false,
  phoneNumberType: PhoneNumberType.Mobile,
};

export const EMPTY_USER: AuthUserFragment = {
  id: "",
  isEmployee: false,
  userContactEmail: { ...EMPTY_USER_CONTACT_EMAIL },
  userContactPhone: { ...EMPTY_USER_CONTACT_PHONE },
  userPersonalDetails: { ...EMPTY_USER_PERSONAL_DETAILS },
  userAddresses: [],
  userIdentityDetails: { ...EMPTY_USER_IDENTITY },
  userEmploymentDetails: [],
  userInvestmentProfile: { ...EMPTY_USER_INVESTMENT_PROFILE },
  userDisclosures: { ...EMPTY_USER_DISCLOSURES },
};
