import Decimal from "decimal.js";
import {
  getNextDepositDate,
  getNextDepositDateOnly,
  validateScheduledDepositConfig,
  ValidateScheduledDepositConfigArgs,
  ValidateScheduledDepositConfigValidationResult,
} from "./scheduledDepositUtils";
import { ZERO } from "../utils";
import {
  AllocationConfigType,
  ScheduledDepositConfig,
} from "../generated/graphql";
import { BUSINESS_TIMEZONE, DateOnly } from "../date_utils";

export enum ValidateAllocationConfigResultEnum {
  PercentageSumInvalid = "PercentageSumInvalid",
  PercentageInvalid = "PercentageInvalid",
  PortfolioRebalancePurchaseOrdersPresent = "PortfolioRebalancePurchaseOrdersPresent",
  PublicApiCashTransfersPresent = "PublicApiCashTransfersPresent",
  ScheduledDepositConfigInvalid = "ScheduledDepositConfigInvalid",
  SubAccountIdsNotUnique = "SubAccountIdsNotUnique",
  SecurityIdsNotUnique = "SecurityIdsNotUnique",
}

export type ValidateAllocationConfigResult = {
  allocationConfigErrors: ValidateAllocationConfigResultEnum[];
  scheduledDepositConfigErrors?: ValidateScheduledDepositConfigValidationResult[];
};

export type ValidateAllocationConfigArgs = {
  type: AllocationConfigType;
  cashTransfers: {
    allocationSubAccountId: string;
    percentage: Decimal;
  }[];
  purchaseOrders: {
    securityId: string;
    percentage: Decimal;
  }[];
  scheduledDepositConfigs?: ValidateScheduledDepositConfigArgs[];
};

export const validateAllocationConfig = (
  args: ValidateAllocationConfigArgs,
): ValidateAllocationConfigResult => {
  const results = new Set<ValidateAllocationConfigResultEnum>();

  // allocation percentages must be positive
  if (args.cashTransfers.some((c) => c.percentage.lte(ZERO))) {
    results.add(ValidateAllocationConfigResultEnum.PercentageInvalid);
  }

  if (args.purchaseOrders.some((p) => p.percentage.lte(ZERO))) {
    results.add(ValidateAllocationConfigResultEnum.PercentageInvalid);
  }

  // Allocation percentages must sum to 100%
  const cashTransfersPercentage = args.cashTransfers.reduce(
    (acc, curr) => acc.plus(curr.percentage),
    ZERO,
  );
  const totalPercentage = cashTransfersPercentage.plus(
    args.purchaseOrders.reduce((acc, curr) => acc.plus(curr.percentage), ZERO),
  );
  if (!totalPercentage.eq(new Decimal(100))) {
    results.add(ValidateAllocationConfigResultEnum.PercentageSumInvalid);
  }

  // subAccountIds should be unique
  const subAccountIds = new Set(
    args.cashTransfers.map((c) => c.allocationSubAccountId),
  );
  if (subAccountIds.size !== args.cashTransfers.length) {
    results.add(ValidateAllocationConfigResultEnum.SubAccountIdsNotUnique);
  }
  // securityIds should be unique
  const securityIds = new Set(args.purchaseOrders.map((p) => p.securityId));
  if (securityIds.size !== args.purchaseOrders.length) {
    results.add(ValidateAllocationConfigResultEnum.SecurityIdsNotUnique);
  }

  // cash transfers should not be present if type is PUBLIC_API
  if (
    args.type === AllocationConfigType.PublicApi &&
    args.cashTransfers.length > 0
  ) {
    results.add(
      ValidateAllocationConfigResultEnum.PublicApiCashTransfersPresent,
    );
  }
  // orders should not be present if type is PORTFOLIO_REBALANCE
  if (
    args.type === AllocationConfigType.PortfolioRebalance &&
    args.purchaseOrders.length > 0
  ) {
    results.add(
      ValidateAllocationConfigResultEnum.PortfolioRebalancePurchaseOrdersPresent,
    );
  }

  // validate scheduled deposit configs
  let scheduledDepositConfigErrors:
    | ValidateScheduledDepositConfigValidationResult[]
    | undefined;
  if (args.scheduledDepositConfigs) {
    args.scheduledDepositConfigs.forEach((sd) => {
      const scheduledConfigResult = validateScheduledDepositConfig(sd);
      if (scheduledConfigResult.length > 0) {
        if (!scheduledDepositConfigErrors) {
          scheduledDepositConfigErrors = [];
        }
        scheduledDepositConfigErrors.push(...scheduledConfigResult);
        results.add(
          ValidateAllocationConfigResultEnum.ScheduledDepositConfigInvalid,
        );
      }
    });
  }

  return {
    allocationConfigErrors: Array.from(results),
    scheduledDepositConfigErrors,
  };
};

export type NormalizedAllocationConfig = Omit<
  ValidateAllocationConfigArgs,
  "scheduledDepositConfigs"
>;

export const normalizeAllocationConfig = (
  args: NormalizedAllocationConfig,
): NormalizedAllocationConfig => {
  // if allocation doesn't sum to 100, normalize by percentage
  const totalPercentage = args.cashTransfers
    .reduce((acc, curr) => acc.plus(curr.percentage), ZERO)
    .plus(
      args.purchaseOrders.reduce(
        (acc, curr) => acc.plus(curr.percentage),
        ZERO,
      ),
    );

  // ensure that this works for cases like 33.33 + 33.33 + 33.33
  // this is to prevent floating point precision issues
  if (!totalPercentage.eq(new Decimal(100))) {
    args.cashTransfers.forEach((c) => {
      c.percentage = c.percentage.div(totalPercentage).mul(100).toDP(2);
    });
    args.purchaseOrders.forEach((p) => {
      p.percentage = p.percentage.div(totalPercentage).mul(100).toDP(2);
    });
  }

  // Calculate the new total after normalization
  const newTotal = [...args.cashTransfers, ...args.purchaseOrders].reduce(
    (acc, curr) => acc.plus(curr.percentage),
    ZERO,
  );

  // If we're not exactly at 100%, adjust the largest allocation
  if (!newTotal.eq(new Decimal(100))) {
    const difference = new Decimal(100).minus(newTotal);
    const allAllocations = [...args.cashTransfers, ...args.purchaseOrders];
    const largest = allAllocations.reduce((max, curr) =>
      curr.percentage.gt(max.percentage) ? curr : max,
    );
    largest.percentage = largest.percentage.plus(difference);
  }

  return args;
};

export type PortfolioAllocationValue = {
  cashTransfers: {
    subAccountId: string;
    value: Decimal;
  }[];
};

export type PortfolioAllocation = {
  cashTransfers: {
    allocationSubAccountId: string;
    percentage: Decimal;
  }[];
};

export const determineAllocationPercentage = (
  allocationValue: PortfolioAllocationValue,
): PortfolioAllocation => {
  const totalValue = allocationValue.cashTransfers.reduce(
    (acc, curr) => acc.plus(curr.value),
    ZERO,
  );

  return {
    cashTransfers: allocationValue.cashTransfers.map((c) => ({
      allocationSubAccountId: c.subAccountId,
      percentage: totalValue.eq(0)
        ? ZERO
        : c.value.div(totalValue).mul(100).toDP(2),
    })),
  };
};

export type AllocationRebalanceAmount = {
  allocationSubAccountId: string;
  amountRequired: Decimal;
};

export const amountRequiredForRebalance = (
  currentAllocationValue: PortfolioAllocationValue,
  targetAllocation: PortfolioAllocation,
): AllocationRebalanceAmount[] => {
  const currentSubAccountValueMap = new Map(
    currentAllocationValue.cashTransfers.map((ct) => [
      ct.subAccountId,
      ct.value,
    ]),
  );

  // Find the highest value account relative to its target percentage
  // This will be our reference point for calculating the target total
  let maxReferenceValue = ZERO;
  targetAllocation.cashTransfers.forEach((target) => {
    const currentValue =
      currentSubAccountValueMap.get(target.allocationSubAccountId) || ZERO;
    // Calculate what the total should be if this account was at its target percentage
    const totalNeeded = currentValue.mul(100).div(target.percentage).toDP(2);
    maxReferenceValue = Decimal.max(maxReferenceValue, totalNeeded);
  });

  return targetAllocation.cashTransfers.map((target) => {
    const currentValue =
      currentSubAccountValueMap.get(target.allocationSubAccountId) || ZERO;
    const targetValue = maxReferenceValue
      .mul(target.percentage)
      .div(100)
      .toDP(2);
    const amountRequired = targetValue.minus(currentValue).toDP(2);

    return {
      allocationSubAccountId: target.allocationSubAccountId,
      amountRequired: amountRequired.gt(ZERO) ? amountRequired.round() : ZERO,
    };
  });
};

export type DepositAllocation = AllocationRebalanceAmount & {
  depositShare: Decimal;
  depositsNeeded: Decimal;
};

export const distributeDepositAmountToRebalance = (
  currentAllocationValue: PortfolioAllocationValue,
  targetAllocation: PortfolioAllocation,
  depositAmount: Decimal,
): DepositAllocation[] => {
  // Get the required amounts for each subAccount
  const rebalanceAmounts = amountRequiredForRebalance(
    currentAllocationValue,
    targetAllocation,
  );

  // Calculate total rebalance amount needed
  const totalRebalanceNeeded = rebalanceAmounts.reduce(
    (sum, ra) => sum.plus(ra.amountRequired),
    ZERO,
  );

  if (!totalRebalanceNeeded.gt(ZERO)) {
    return rebalanceAmounts.map((ra) => ({
      allocationSubAccountId: ra.allocationSubAccountId,
      amountRequired: ra.amountRequired,
      depositShare: ZERO,
      depositsNeeded: ZERO,
    }));
  }

  return rebalanceAmounts.map((ra) => {
    // Calculate this account's share of each deposit based on its portion of total needed
    const depositShare = new Decimal(depositAmount)
      .mul(ra.amountRequired)
      .div(totalRebalanceNeeded)
      .toDP(2);

    return {
      allocationSubAccountId: ra.allocationSubAccountId,
      amountRequired: ra.amountRequired,
      depositShare,
      depositsNeeded: depositShare.eq(0)
        ? ZERO
        : /**
           * Ideally Decimal.ceil would be used here, but it rounds up to the next integer
           * which is not always correct. For example, 1000/333.33=rounds up to 4
           * So we're using a manual rounding method instead.
           */
          ra.amountRequired.div(depositShare).toDP(0, Decimal.ROUND_HALF_CEIL),
    };
  });
};

export type RebalanceCompleteDate = {
  allocationSubAccountId: string;
  daysUntilCompletionFromToday: number;
  depositsNeeded: number;
  dateUntilCompletion: DateOnly | null;
};

export const getRebalanceCompleteDates = (
  currentAllocationValue: PortfolioAllocationValue,
  targetAllocation: PortfolioAllocation,
  scheduledDepositConfig: ScheduledDepositConfig,
): RebalanceCompleteDate[] => {
  const depositAmounts = distributeDepositAmountToRebalance(
    currentAllocationValue,
    targetAllocation,
    scheduledDepositConfig.amount,
  );

  return depositAmounts.map((ra) => {
    if (!ra.amountRequired.gt(ZERO)) {
      return {
        allocationSubAccountId: ra.allocationSubAccountId,
        daysUntilCompletionFromToday: 0,
        depositsNeeded: 0,
        dateUntilCompletion: null,
      };
    }
    // Calculate completion date
    const nextDepositDate = getNextDepositDate({
      dayOfPeriod: scheduledDepositConfig.dayOfPeriod ?? 1,
      secondaryDayOfPeriod:
        scheduledDepositConfig.secondaryDayOfPeriod ?? undefined,
      periodType: scheduledDepositConfig.periodType,
      startAt: DateOnly.fromDateTz(
        scheduledDepositConfig.startAt,
        BUSINESS_TIMEZONE,
      ),
    });
    // Calculate total days needed
    let depositsLeft = ra.depositsNeeded.minus(1);
    let lastDepositDate = nextDepositDate;
    while (depositsLeft.gt(0)) {
      lastDepositDate = getNextDepositDateOnly({
        referenceDate: lastDepositDate.nextDay(),
        periodType: scheduledDepositConfig.periodType,
        dayOfPeriod: scheduledDepositConfig.dayOfPeriod ?? 1,
        secondaryDayOfPeriod:
          scheduledDepositConfig.secondaryDayOfPeriod ?? undefined,
        lastDepositDate,
      });
      depositsLeft = depositsLeft.minus(1);
    }

    const totalDaysNeeded = lastDepositDate.diff(nextDepositDate, "days");

    const today = DateOnly.now(BUSINESS_TIMEZONE);

    const daysUntilDepositStarts = Decimal.max(
      nextDepositDate.diff(today, "days"),
      0,
    ).toNumber();

    return {
      allocationSubAccountId: ra.allocationSubAccountId,
      daysUntilCompletionFromToday: totalDaysNeeded + daysUntilDepositStarts,
      depositsNeeded: ra.depositsNeeded.toNumber(),
      dateUntilCompletion: lastDepositDate,
    };
  });
};
