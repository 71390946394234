import * as amplitude from "@amplitude/analytics-browser";
import { Logger as AmplitudeLogger } from "@amplitude/analytics-types";
import { Logger as DDLogger } from "@datadog/browser-logs";

import { TagCustomEvent } from "../tagging";

/**
 * Export only the subset of amplitude that we use; and add
 * types for our custom events
 */
export const init = (
  apiKey: string,
  opts: { loggerProvider: AmplitudeLogger },
): void => {
  amplitude.init(apiKey, opts);
};

export const makeAmplitudeLogger = (l: DDLogger): AmplitudeLogger => {
  /**
   * FREC-2434: do not log fetch errors from amplitude
   *
   * These regexes will be compared against the stringified versions
   * of the context object passed to the logger, which is matched
   * indicate to log as a warn rather than an error
   */
  const warningLogMatches = [
    /Failed to fetch/,
    /Load failed/,
    /NetworkError when attempting to fetch resource/,
  ];

  const shouldMakeWarning = (...args: any[]): boolean => {
    const s = args.join(" ");
    return !!warningLogMatches.find((rex) => rex.test(s));
  };

  return {
    disable(): void {
      // noop
    },
    enable(): void {
      // noop
    },
    debug(...args: any[]): void {
      l.debug(args[0], { args });
    },
    log(...args: any[]): void {
      l.info(args[0], { args });
    },
    warn(...args: any[]): void {
      l.warn(args[0], { args });
    },
    error(...args: any[]): void {
      if (shouldMakeWarning(args)) {
        l.warn(args[0], { args });
      } else {
        l.error(args[0], { args });
      }
    },
  };
};

export const identify: (args: { userId: string }) => void = ({ userId }) => {
  amplitude.setUserId(userId);
};

export const track = (e: TagCustomEvent): void => {
  amplitude.track(e);
};
