import { DirectIndexAnalysisAccount } from "../generated/graphql";
import { makeDecimal, ZERO } from "./number";

// Column names in the cost basis CSV for different brokerages.
const BrokerageTypeToColumn = {
  [DirectIndexAnalysisAccount.Wealthfront]: "Market Value",
  [DirectIndexAnalysisAccount.Betterment]: "MarketValue",
  [DirectIndexAnalysisAccount.Fidelity]: "Current Value",
  [DirectIndexAnalysisAccount.CharlesSchwab]: "Mkt Val (Market Value)",
} as const;

/**
 * Looks for the column with the given name in the CSV and sums all the values in that column.
 */
const sumCSVColumn = (csv: string, columnName: string) => {
  const lines = csv.split("\n");
  const headerIndex = lines.findIndex((line) => line.includes(columnName));

  if (headerIndex === -1) {
    throw new Error(`${columnName} column not found in CSV`);
  }

  const headers = lines[headerIndex].split(",");
  const columnIndex = headers.findIndex((header) =>
    header.includes(columnName),
  );

  if (columnIndex === -1) {
    throw new Error(`${columnName} column not found in CSV`);
  }

  let sum = ZERO;

  for (let i = headerIndex + 1; i < lines.length; i++) {
    const line = lines[i].trim();
    if (line) {
      const values = line.split(",");
      if (values[columnIndex]) {
        const value = makeDecimal(values[columnIndex].replace(/[$\"\']/g, ""));
        sum = sum.plus(value);
      }
    }
  }

  return sum.toDecimalPlaces(2);
};

/**
 * Gets the value of the last row in the column with the given name.
 */
const lastValueCSVColumn = (csv: string, columnName: string) => {
  const lines = csv.split("\n");
  const headerIndex = lines.findIndex((line) => line.includes(columnName));

  if (headerIndex === -1) {
    throw new Error(`${columnName} column not found in CSV`);
  }

  const headers = lines[headerIndex].split(",");
  const columnIndex = headers.findIndex((header) =>
    header.includes(columnName),
  );

  if (columnIndex === -1) {
    throw new Error(`${columnName} column not found in CSV`);
  }

  let lastValue = ZERO;
  const filteredLines = lines.filter((line) => line);
  const lastLine = filteredLines[filteredLines.length - 1].trim();
  const values = lastLine.split(",");
  if (values[columnIndex]) {
    lastValue = makeDecimal(values[columnIndex].replace(/[$\"\']/g, ""));
  }

  return lastValue.toDecimalPlaces(2);
};

/**
 * Extracts the portfolio value (market value) from the cost basis CSV.
 */
export const portfolioValueFromCostBasisCSV = (
  csv: string,
  accountType?: DirectIndexAnalysisAccount,
) => {
  switch (accountType) {
    case DirectIndexAnalysisAccount.Wealthfront:
      return sumCSVColumn(
        csv,
        BrokerageTypeToColumn[DirectIndexAnalysisAccount.Wealthfront],
      );
    case DirectIndexAnalysisAccount.Betterment:
      return sumCSVColumn(
        csv,
        BrokerageTypeToColumn[DirectIndexAnalysisAccount.Betterment],
      );
    case DirectIndexAnalysisAccount.Fidelity:
      return sumCSVColumn(
        csv,
        BrokerageTypeToColumn[DirectIndexAnalysisAccount.Fidelity],
      );
    case DirectIndexAnalysisAccount.CharlesSchwab:
      return lastValueCSVColumn(
        csv,
        BrokerageTypeToColumn[DirectIndexAnalysisAccount.CharlesSchwab],
      );
    default:
      throw new Error("Unsupported account type");
  }
};
