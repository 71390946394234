import { mean, mode } from "simple-statistics";

import { DateOnly } from "../date_utils";
import { DatePairPoint, DatePoint, SymbolBeta } from "./model";

export function niceTicks(minVal: number, maxVal: number, numTicks: number) {
  const niceNumbers = [
    0.01, 0.05, 0.1, 0.5, 1, 5, 10, 50, 100, 500, 1000, 5000, 10000, 50000,
  ];

  const stepSize = (maxVal - minVal) / numTicks;
  // find the closest nice number
  const closest = niceNumbers.reduce(
    (p, c) => {
      const diff = Math.abs(stepSize - c);
      return diff < p[0] ? [diff, c] : p;
    },
    [Number.MAX_VALUE, 0],
  );

  const arr: number[] = [];
  let tick = Math.floor(minVal / closest[1]) * closest[1];
  arr.push(tick);
  do {
    tick += closest[1];
    arr.push(tick);
  } while (tick < maxVal);

  return arr;
}

/**
 * Compute the deltas of a time series. The deltas are computed by dividing the value of
 * a point by the value of the next point. The result is a time series with one element
 * less than the original.
 *
 * The first date in the series is kept and the last date is dropped. Divide by zero is
 * not handled.
 */
export function computeDeltas(d: DatePoint[]): DatePairPoint[] {
  return d.flatMap((p, i) => {
    if (i === d.length - 1) return [];
    return {
      startDate: p.date,
      endDate: d[i + 1].date,
      value: d[i + 1].value / p.value - 1,
    };
  });
}

/**
 * Time series are messy, "daily" time series often include only business days, weekly durations
 * sometimes have 6 or 8 days apart due to holidays, etc. This function attempts to guess the
 * resolution of a time series by computing the mode duration between dates, which should remove
 * any outliers resulting from holidays, then comparing to common resolutions.
 *
 * If no obvious resolution is found, undefined is returned.
 */
export function approximateResolution(
  d: DatePoint[],
): "day" | "week" | "month" | "year" | undefined {
  // build an array of durations between dates
  const durations = d.flatMap((p, i) => {
    if (i === d.length - 1) return [];
    return Math.abs(d[i + 1].date.diff(p.date, "days"));
  });

  const modeDuration = mode(durations);
  if (modeDuration === undefined) return undefined;

  // days and weeks are easy, almost all durations are 1 or 7
  if (modeDuration === 1 || modeDuration === 7) {
    // At least 50% of the durations should match
    if (
      durations.filter((d) => d === modeDuration).length <
      durations.length * 0.6
    )
      return undefined;
    if (modeDuration === 1) return "day";
    if (modeDuration == 7) return "week";
  }

  // months and years are harder, month data has longer durations when the days land on weekends
  // and shorter durations when the previous month had a longer duration.
  const meanDuration = mean(durations);

  // worst-case we have one of the dates land on a on a 4-day long weekend which causes a shift
  // of 4 days, so max duration is 4 + 31 = 35 and min duration is 28 - 4 = 24
  if (meanDuration >= 29 && meanDuration <= 32) {
    if (durations.find((d) => d < 24 || d > 35)) return undefined;
    return "month";
  }

  if (meanDuration >= 360 && modeDuration < 370) return "year";
  return undefined;
}

/**
 * The general idea is that if, say, delta is +50%, and beta is 2.0 then result is 100%,
 * or if beta is 0.5 then result is 25%. But, what if the delta is -75%? If beta is 2.0
 * then result is -150%, which is not a valid percentage for a stock price.
 *
 * So, when the result would result in a < -90% drop, we transform it through a sigmoid
 * function so that the result is between -90% and -100%.
 */
export function deltaSquash(delta: number, beta: number) {
  const x = delta * beta;
  if (x > -0.9) return x;
  return 2 / (1 + Math.exp(-3.275 * x)) - 1;
}

export function constantBetaLookup(betas: SymbolBeta[]) {
  const lookup = new Map<string, number>();
  for (const b of betas) {
    lookup.set(b.symbol, b.beta);
  }

  return (date: DateOnly, symbol: string) => {
    return lookup.get(symbol) ?? 1;
  };
}
