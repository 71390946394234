import {
  ACH_DEPOSIT_MIN,
  assertUnreachable,
  formatUsd,
  MINIMUM_TRADE_VALUE,
  ZERO,
} from "@frec-js/common";

import { TreasurySetupFlowProps } from "../types";

export enum TreasurySetupValidationError {
  InsufficientBalance = "InsufficientBalance",
  WholeNumbersOnly = "WholeNumbersOnly",
  AchAmountShouldBeGreaterThanMin = "AchDepositAmountShouldBeGreaterThanMin",
  TradeValueTooLow = "TradeValueTooLow",
  AchAmountTooLargeNeedsWire = "AchAmountTooLargeNeedsWire",
}

export const generateTreasurySetupValidationErrorMessage = (
  error: TreasurySetupValidationError | undefined | null,
  ctx: Pick<TreasurySetupFlowProps, "amount" | "sourceAccount">,
) => {
  // If error is null means the inputs are invalid but we don't want to show
  //  any error message maybe because user hasn't entered anything yet
  if (!error) return error;

  const { amount, sourceAccount } = ctx;
  switch (error) {
    case TreasurySetupValidationError.InsufficientBalance:
      return `Cannot transfer more than your current balance of ${formatUsd(
        sourceAccount?.balance ?? ZERO,
        0,
        2,
      )}.`;
    case TreasurySetupValidationError.WholeNumbersOnly:
      return "The amount to invest must be a whole number of dollars";
    case TreasurySetupValidationError.AchAmountShouldBeGreaterThanMin:
      return `The minimum amount for ACH transfers is ${formatUsd(
        ACH_DEPOSIT_MIN,
      )}.`;
    case TreasurySetupValidationError.TradeValueTooLow:
      return `Trade value should be greater than $${MINIMUM_TRADE_VALUE}`;
    case TreasurySetupValidationError.AchAmountTooLargeNeedsWire:
      return `The total amount of ${formatUsd(
        amount,
      )} exceeds the daily limit for ACH transfers. You can wire instead.`;
    default:
      assertUnreachable(error);
  }
};
