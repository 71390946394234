import Decimal from "decimal.js";
import { cloneDeep } from "lodash";

import { DateOnly } from "../date_utils";
import {
  combineHoldings,
  getConcentratedAdjustedMargin,
  getDailyInterestCharge,
  getMarginRequirement,
  getMaxBorrowAmount,
  getSecurityHoldingsValue,
} from "../portfolio_utils";
import {
  InsufficientBorrowingPowerError,
  InsufficientPortfolioValueForSale as InsufficientPortfolioValueForSaleError,
  SBActionType,
  SBSimulationHolding,
  SBSimulationScenario,
  SBSimulationState,
} from "./model";

const MS_IN_DAY = 86400000;
const MIN_HOLDING_VALUE = 1; // If the portfolio value drops below this amount, don't bother selling to cover.
const REINVEST_DIVIDENDS_FLAG = true;

export class SellBorrowSimulator {
  /**
   * Computes new security prices and loan interest charges. Advances to the next date stored in the
   * `this.seedDeltas` array.
   */
  private stepForward(
    state: SBSimulationState,
    scenario: SBSimulationScenario,
  ): SBSimulationState {
    const newDate = scenario.symbolPriceTimeSeries.nextDate(state.date);

    if (!newDate) {
      throw new Error("Attempting to simulate past the end of the time series");
    }

    const dayDiff = Math.round(
      (newDate.valueOf() - state.date.valueOf()) / MS_IN_DAY,
    );

    const newHoldings: SBSimulationHolding[] = [];
    for (const holding of state.holdings) {
      const newPrice = scenario.symbolPriceTimeSeries.lookup({
        date: newDate,
        symbol: holding.symbol,
      });

      // deep clone is not actually needed, future safe
      newHoldings.push({ ...holding, price: newPrice });
    }

    // compute interest and add it to the loan, we assume that any new loans
    // are taken out on the simulated intervals (i.e. `newDate`), so we update
    // to the new loan later, and the incremental interest on that will be
    // calculated next loop.
    const dailyInterestCharge = getDailyInterestCharge(
      scenario.interestRate(state.date),
      state.loan,
    );

    // Note: interest compounds with each step, not necessarily daily
    const interest = dailyInterestCharge.mul(dayDiff);
    const newInterest = state.interestPaid.add(interest);

    /**
     * scenario `loanAmount` amount is cumulative so calculate the delta between
     * the last date and the current date
     */
    const incrementalLoanAmount = scenario
      .loanAmount(newDate)
      .minus(scenario.loanAmount(state.date));

    // update the loan amount
    const loanWithInterestAdded = state.loan
      .add(incrementalLoanAmount)
      .add(interest);

    const maxBorrow = getMaxBorrowAmount({ holdings: newHoldings });
    if (incrementalLoanAmount.gt(0) && loanWithInterestAdded.gt(maxBorrow)) {
      throw new InsufficientBorrowingPowerError(
        `incremental loan amount: ${incrementalLoanAmount} exceeds borrowing power.`,
      );
    }

    // Compute and re-invest dividends.
    const { cashDelta, holdingsDelta, taxPaid } = this._processDividends(
      state.date, // we use last date for dividend rate
      this.getDividendTaxRate(scenario),
      newHoldings,
      dayDiff,
      REINVEST_DIVIDENDS_FLAG,
    );

    const newState: SBSimulationState = {
      date: newDate,
      holdings: combineHoldings([newHoldings, holdingsDelta]),
      cash: state.cash.add(cashDelta),
      loan: loanWithInterestAdded,
      interestPaid: newInterest,
      taxPaid: state.taxPaid.add(taxPaid),
      taxUnpaid: state.taxUnpaid,
      actions: [],
    };

    /**
     * scenario sell amount is cumulative so calculate the delta between
     * the last date and the current date
     */
    const incrementalSell = scenario
      .sell(newDate)
      .minus(scenario.sell(state.date));

    if (incrementalSell.gt(getSecurityHoldingsValue(newHoldings))) {
      throw new InsufficientPortfolioValueForSaleError(
        `incremental sell amount: ${incrementalSell} exceeds portfolio value. newDate: ${newDate} scenario
        .sell(newDate) ${scenario.sell(
          newDate,
        )} scenario.sell(state.date) ${scenario.sell(state.date)}`,
      );
    }

    // TODO: it would be good to be consistent about how we are mutating state, perhaps
    // _sell should return only the objects that it changes.
    const reallyNewState = this._sell(
      incrementalSell,
      this.getTaxRate(scenario),
      newState,
      false,
    );

    const reallyReallyNewState = this._rebalancePortfolio(
      this.getTaxRate(scenario),
      reallyNewState,
    );

    // catches a rare edge case where a margin call could not sell off fast enough
    if (
      getSecurityHoldingsValue(reallyReallyNewState.holdings).lte(
        reallyReallyNewState.loan,
      )
    ) {
      return {
        ...reallyReallyNewState,
        holdings: reallyReallyNewState.holdings.map((h) => ({
          ...h,
          quantity: new Decimal(0),
        })),
        loan: new Decimal(0),
      };
    }

    return reallyReallyNewState;
  }

  /**
   * For any holdings that pay out dividends, compute the amount of dividends that
   * should be paid out, and add them to the cash balance or reinvest them into the
   * same security if `reinvestDividends` is set to true.
   *
   * TODO: Like interest rates, dividends may pay out on a quarterly or monthly basis,
   * so it would be more accurate to have a "last dividend date" added to the state, as
   * well as other flags indicating when dividends should be paid out.
   */
  _processDividends(
    date: DateOnly,
    dividendTaxRate: Decimal,
    holdings: SBSimulationHolding[],
    dayDiff: number,
    reinvestDividends: boolean,
  ): {
    cashDelta: Decimal;
    holdingsDelta: SBSimulationHolding[];
    taxPaid: Decimal;
  } {
    let cashDelta = new Decimal(0);
    let taxPaid = new Decimal(0);
    const holdingsDelta = [];

    for (const holding of holdings) {
      if (
        holding.dividendYield &&
        holding.dividendYield(date)?.getRate().gt(0)
      ) {
        const dividend = holding
          .dividendYield(date)
          .getRate()
          .mul(holding.quantity)
          .mul(holding.price)
          .mul(dayDiff)
          .div(360); // 360 days in a year to keep consistent with interest rate calcs

        const taxForCurrentHolding = dividend.mul(dividendTaxRate);
        taxPaid = taxPaid.add(taxForCurrentHolding);
        const dividendAfterTax = dividend.sub(taxForCurrentHolding);

        if (reinvestDividends) {
          const quantity = dividendAfterTax.div(holding.price);
          holdingsDelta.push({
            ...holding,
            quantity,
            averageCostBasis: holding.price,
          });
        } else {
          cashDelta = cashDelta.add(dividendAfterTax);
        }
      }
    }

    return { cashDelta, holdingsDelta, taxPaid: taxPaid };
  }

  /**
   * If the portfolio is in margin call, sells off assets to clear it, return a new state.
   */
  // VisibleForTesting
  _rebalancePortfolio(
    taxRate: Decimal,
    state: SBSimulationState,
  ): SBSimulationState {
    // avoid mutating passed in argument.
    const newState = cloneDeep(state);
    const maxBorrowAmountInit = getMaxBorrowAmount({
      holdings: state.holdings,
    });
    // If not in margin call, return.
    if (state.loan.lessThanOrEqualTo(maxBorrowAmountInit)) return state;

    // Note: using the portfolio margin requirement below could be an overestimate; we could sell off a
    // higher-margin stock in some scenarios. Determining the optimal amount to sell does not have an
    // obvious closed-form solution, however. (would require a solver).
    const initShortfall = state.loan
      .sub(maxBorrowAmountInit)
      .div(getMarginRequirement(state.holdings));

    // Rebalance algorithm works by selling off stock with the highest margin requirement first, but may
    // not be able to sell enough (because it can't sell any securities not in the
    // tied-for-highest-margin-requirement group), so we loop.
    const rebalanceLoop: (
      shortfall?: Decimal,
      maxBorrowAmount?: Decimal,
      iteration?: number,
      acc?: SBSimulationState,
    ) => SBSimulationState = (
      shortfall: Decimal = initShortfall,
      maxBorrowAmount: Decimal = maxBorrowAmountInit,
      iteration = 0,
      acc: SBSimulationState = newState,
    ) => {
      if (iteration > 1000) {
        throw new Error("rebalanceLoop loop exceeded 1000 iterations");
      }

      if (shortfall.gt(0) && maxBorrowAmount.greaterThan(MIN_HOLDING_VALUE)) {
        const next = this._sell(shortfall, taxRate, acc, true);

        const nextMaxBorrowAmount = getMaxBorrowAmount({
          holdings: next.holdings,
        });

        const nextShortfall = next.loan
          .sub(nextMaxBorrowAmount)
          .div(getMarginRequirement(next.holdings));

        return rebalanceLoop(
          nextShortfall,
          nextMaxBorrowAmount,
          iteration + 1,
          next,
        );
      } else {
        return acc;
      }
    };

    return rebalanceLoop();
  }

  /**
   * Sells cash amount across holdings evenly, paying down loan if present
   */
  // VisibleForTesting
  _sell(
    pretaxSaleAmount: Decimal,
    taxRate: Decimal,
    state: SBSimulationState,
    marginCall: boolean,
  ): SBSimulationState {
    // avoid mutating passed in argument.
    const newState = cloneDeep(state);

    if (pretaxSaleAmount.lessThanOrEqualTo(0)) return newState;

    const value = getSecurityHoldingsValue(newState.holdings);

    // For margin call we don't sell more to pay taxes, below we do these calculations
    const weightedAvgCost = state.holdings.reduce((acc, h) => {
      return acc.add(h.averageCostBasis.mul(h.quantity));
    }, new Decimal(0));

    // If portfolio has 25% appreciation (say), and tax rate is 20%, then effective tax is 5%.
    const effectiveTaxRate = taxRate.mul(
      Decimal.max(0, new Decimal(1).minus(weightedAvgCost.div(value))),
    );

    let saleAmount = marginCall
      ? pretaxSaleAmount
      : pretaxSaleAmount.div(1 - effectiveTaxRate.toNumber());

    let holdingsToSell = newState.holdings;

    // if we are in a margin call, we need to sell off holdings with the highest margin
    // requirements first. For non-margin call, we sell off holdings equally.
    if (marginCall) {
      // get the concentrated margin requirements for each holding, as well as the maximum
      let maxMarginReq = new Decimal(0);
      const concentratedRequirements = new Map<string, Decimal>();
      newState.holdings.forEach((h) => {
        const mr = getConcentratedAdjustedMargin(h, value);
        if (mr.greaterThan(maxMarginReq)) maxMarginReq = mr;
        concentratedRequirements.set(h.symbol, mr);
      });

      // get holdings with the same margin requirements (the max) and sort in ascending order of holding
      // value.
      holdingsToSell = newState.holdings
        .filter((h) =>
          (concentratedRequirements.get(h.symbol) as Decimal).equals(
            maxMarginReq,
          ),
        )
        .sort((a, b) =>
          a.quantity.mul(a.price).sub(b.quantity.mul(b.price)).toNumber(),
        );
    }

    // sell off holdings as evenly as possible.
    const n = holdingsToSell.length;
    for (let i = 0; i < n; i++) {
      // figure out how much to sell, may exceed quantity owned, that's fine
      const evenSell = saleAmount.div(n - i);

      const shares = evenSell.div(holdingsToSell[i].price);

      // sell the holding
      const { proceeds, capGains } = this.sellHolding(
        holdingsToSell[i],
        shares,
      );
      saleAmount = saleAmount.sub(proceeds);
      const taxOwed = capGains.mul(taxRate);

      // for margin-call sales, we don't pay taxes, but we need to record the unpaid taxes
      const [taxPaid, taxUnpaid] = marginCall
        ? [new Decimal(0), taxOwed]
        : [taxOwed, new Decimal(0)];
      const afterTaxProceeds = proceeds.sub(taxPaid);

      newState.taxPaid = newState.taxPaid.add(taxPaid);
      newState.taxUnpaid = newState.taxUnpaid.add(taxUnpaid);

      if (marginCall) {
        newState.actions.push({
          action: SBActionType.MARGIN_CALL_SELL,
          amount: proceeds,
        });
      }

      // pay off loan, or add to cash if loan is paid
      if (proceeds.gte(newState.loan)) {
        newState.cash = newState.cash.add(afterTaxProceeds.sub(newState.loan));
        newState.loan = new Decimal(0);
      } else {
        newState.loan = newState.loan.sub(afterTaxProceeds);
      }
    }

    // Note: taxPaid/taxOwed may be negative, so it will carry-forward to the next time step.
    // This may or may not be what you might expect.
    return newState;
  }

  // "sells" a holdings by mutating `quantity` and returns the cash proceeds
  // `capGains` may be negative if the holding is a loss
  private sellHolding(
    holding: SBSimulationHolding,
    shares: Decimal,
  ): { proceeds: Decimal; capGains: Decimal } {
    const s = Decimal.min(shares, holding.quantity);
    holding.quantity = holding.quantity.sub(s);
    const proceeds = s.mul(holding.price);
    const capGains = proceeds.sub(holding.averageCostBasis.mul(s));
    return { proceeds, capGains };
  }

  simulate(scenario: SBSimulationScenario): SBSimulationState[] {
    const initialSold = scenario.sell(scenario.symbolPriceTimeSeries.dataStart);
    const baseState: SBSimulationState = {
      date: scenario.startDate,
      holdings: scenario.initialHoldings,
      cash: scenario.cash,
      loan: scenario.loanAmount(scenario.startDate),
      interestPaid: new Decimal(0),
      taxPaid: new Decimal(0),
      taxUnpaid: new Decimal(0),
      actions: [],
    };

    // sell initial amount of stock to build initial state
    let state = this._sell(
      initialSold,
      this.getTaxRate(scenario),
      baseState,
      false, // not a margin call
    );

    // simulate until `nextDate` returns undefined
    const { nextDate } = scenario.symbolPriceTimeSeries;
    const states = [state];
    while (nextDate(state.date) !== undefined) {
      state = this.stepForward(state, scenario);
      states.push(state);
    }
    return states;
  }

  /**
   * Get tax rate from scenario, or default to 0
   * @param taxRate
   */
  private getTaxRate = ({ taxRate }: SBSimulationScenario): Decimal =>
    taxRate ?? new Decimal(0);

  /**
   * Get tax rate from scenario, or default to 0
   * @param taxRate
   */
  private getDividendTaxRate = ({
    dividendTaxRate,
  }: SBSimulationScenario): Decimal => dividendTaxRate ?? new Decimal(0);
}
